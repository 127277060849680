import { Card, Flex, useToast } from '@chakra-ui/react'
import React, { FC } from 'react'
import { useQuery } from 'react-query'
import { useParams } from 'react-router-dom'
import { HESTIA_COLORS } from '../../../App'
import { getPrettyNumber } from '../../../utility/money'
import Bold from '../../components/Typo/Bold'
import Header from '../../components/Typo/Header'
import UserFinancialSummary from '../../components/UserFinancialSummary'
import { getUserQuery } from '../queries/userQuery'
import UserPartnerInformations from './UserPartnerInformations'
import UserProcessStagesList from './UserProcessStagesList'
import UserQuickActions from './UserQuickActions'

const UserSummary: FC<{}> = ({}) => {
  const toast = useToast()
  const { id } = useParams()
  const userQuery = useQuery(['user', { id }], () => getUserQuery({ id }))

  return (
    <>
      <Flex flexDirection='column' gap='24px'>
        <Flex gap='24px'>
          <Flex w='50%'>
            <UserProcessStagesList />
          </Flex>
          <Flex w='50%' direction='column' gap='24px'>
            <UserQuickActions />
            {userQuery.data?.user && <UserFinancialSummary user={userQuery.data?.user} />}
          </Flex>
        </Flex>
        <Flex w='50%' direction='column' gap='24px'>
          {userQuery.data?.user.userInformations?.customBudgetOverride &&
          userQuery.data?.user.userInformations?.customBudgetOverride > 0 ? (
            <Card border='1px solid' borderColor={HESTIA_COLORS.brand.green.primary}>
              <Flex direction='column' gap={2} padding='16px'>
                <Bold>Budget écrasé manuellement :</Bold>
                <Header size='h3' color={HESTIA_COLORS.brand.green.primary}>
                  {getPrettyNumber(userQuery.data?.user.userInformations?.customBudgetOverride)}
                </Header>
              </Flex>
            </Card>
          ) : (
            <></>
          )}
          {userQuery.data?.user.partnerUserInformations?.adExternalId && (
            <UserPartnerInformations />
          )}
        </Flex>
      </Flex>
    </>
  )
}

export default UserSummary
