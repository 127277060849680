import { Card, Flex } from '@chakra-ui/react'
import _ from 'lodash'
import React, { useEffect, useState } from 'react'
import {
  LineChart,
  XAxis,
  YAxis,
  Label,
  Tooltip,
  CartesianGrid,
  ReferenceArea,
  ReferenceLine,
  Line,
  ResponsiveContainer,
  Legend,
} from 'recharts'
import {
  getClosestInArrayOfObject,
  getObjectWithClosestDebtToIncomeRatio,
  getPrettyNumber,
} from '../../../utility/money'
import Bold from '../../components/Typo/Bold'
import Regular from '../../components/Typo/Regular'
import { FormikValues, useFormikContext } from 'formik'

type Props = {
  acquisitionPrice: number
  monthlyAvailable: number
  complementarySavingsNeeded: number
}

interface RentInfo {
  rentalYield: number
  rentPrice: number
}

const RentalYieldvsRentChart = (props: Props) => {
  const numberOfDataPoints: number = (8 - 3) / 0.05 + 1
  const formik = useFormikContext<FormikValues>()

  console.log(
    props.monthlyAvailable - props.complementarySavingsNeeded,
    props.monthlyAvailable,
    props.complementarySavingsNeeded,
  )

  const chartData: RentInfo[] = []

  for (let i = 3; i <= 8; i += 0.05) {
    const rentalYield = i / 100
    const rentPrice = (props.acquisitionPrice * rentalYield) / 12
    chartData.push({ rentalYield, rentPrice })
  }

  const minValue = _.minBy(chartData, 'rentPrice')?.rentPrice || 0
  const maxValue = _.maxBy(chartData, 'rentPrice')?.rentPrice || 0

  return (
    <Flex direction='column' gap='8px' w='100%'>
      <Bold>Rentabilité locative</Bold>
      <ResponsiveContainer width='100%' height={250}>
        <LineChart
          onClick={(value) => {
            console.log(value)
            formik.setFieldValue('rentalYield', (Number(value.activeLabel) * 100).toFixed(2))
          }}
          style={{
            cursor: 'pointer',
          }}
          data={chartData}
          margin={{
            top: 0,
            right: 0,
            left: 0,
            bottom: 25,
          }}
        >
          <CartesianGrid strokeDasharray='3 3' />

          <ReferenceLine
            x={
              getClosestInArrayOfObject(chartData, 'rentalYield', formik.values.rentalYield / 100)
                ?.rentalYield
            }
            stroke='blue'
            strokeWidth={2}
            fillOpacity={1}
            // x2={
            //   getObjectWithClosestDebtToIncomeRatio(chartData, 1)
            //     .hestiaDebtToIncomeRatio
            // } cd
          />
          <Legend
            payload={[
              { value: '> 40% du salaire', type: 'square', color: 'coral', id: 'ID01' },
              { value: '> Epargne complémentaire', type: 'square', color: 'skyblue', id: 'ID01' },
              { value: 'Parfait', type: 'square', color: 'green', id: 'ID01' },
            ]}
          />
          <XAxis dataKey='rentalYield' tickFormatter={(tick) => `${(tick * 100).toFixed(1)}%`} />
          <YAxis
            dataKey='rentPrice'
            type='number'
            tickFormatter={(tick) => `${getPrettyNumber(tick, 0, false)}`}
            domain={[minValue - minValue * 0.02, maxValue + maxValue * 0.02]}
            width={100}
          />
          <ReferenceArea
            y1={getClosestInArrayOfObject(chartData, 'rentPrice', 0)?.rentPrice}
            y2={
              getClosestInArrayOfObject(
                chartData,
                'rentPrice',
                props.monthlyAvailable - props.complementarySavingsNeeded,
              )?.rentPrice
            }
            fill='lightgreen'
            fillOpacity={0.3}
          />

          <ReferenceArea
            y1={
              getClosestInArrayOfObject(
                chartData,
                'rentPrice',
                props.monthlyAvailable - props.complementarySavingsNeeded,
              )?.rentPrice
            }
            y2={
              getClosestInArrayOfObject(chartData, 'rentPrice', props.monthlyAvailable)?.rentPrice
            }
            fill='lightskyblue'
            fillOpacity={0.4}
          />
          <ReferenceArea
            y1={
              getClosestInArrayOfObject(chartData, 'rentPrice', props.monthlyAvailable)?.rentPrice
            }
            y2={getClosestInArrayOfObject(chartData, 'rentPrice', 10000000 * 10)?.rentPrice}
            fill='lightcoral'
            fillOpacity={0.4}
          />
          <Tooltip
            content={({ active, payload, label }) => {
              if (active && payload && payload.length) {
                return (
                  <Card bg='white' padding='16px'>
                    <Flex gap='8px' direction='column'>
                      <Regular>
                        Redevance mensuelle:{' '}
                        <b>{`${getPrettyNumber(
                          parseFloat(payload[0].value?.toString() || '0'),
                          0,
                          false,
                        )}`}</b>
                      </Regular>
                      <Regular>
                        Rentabilité locative:{' '}
                        <b>{`${(parseFloat(label || '0') * 100).toFixed(2)}`}%</b>
                      </Regular>
                    </Flex>
                  </Card>
                )
              }
              return null
            }}
          />

          <Line dataKey='rentPrice' dot={false} animationDuration={1000} strokeWidth={2} />
          <Tooltip />
        </LineChart>
      </ResponsiveContainer>
    </Flex>
  )
}

export default RentalYieldvsRentChart
