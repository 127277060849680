import { UserLostReasonEnum } from '../../../interfaces/prisma'
import { AccountStateEnum, FolderStatusEnum } from '../../../interfaces/user'
import { UserAnalaysisRejectionReasonEnum } from '../../../shared/backShared/userAnalysisRejectionTypes'
import { UserProcessStagesEnum } from '../../../shared/backShared/userProcessStages'
import { authenticatedGetQuery } from '../../../utility/queries/authenticatedGetQuery'
import { Partner, PartnerAgency, PartnerPerson } from '../../partners/queries/getPartnerQuery'

export interface GetUserQueryInputs {
  id?: string
}

export interface InseePostalCode {
  id: string
  postalCode: string
  latitude: number
  longitude: number
  region: string
  department: string
}

export type PartnerUserInformations = {
  agencySource?: PartnerAgency
  partnerSource?: Partner
  personSource?: PartnerPerson
  id: string
  createdAt: Date
  updatedAt: Date
  userId: string
  partnerId: string | null
  agencyId: string | null
  personId: string | null
  adExternalId: string | null
  adUrl: string | null
  adTitle: string | null
  adDescription: string | null
  adPrice: number | null
  adLatitude: number | null
  adLongitude: number | null
  adSize: number | null
  adRooms: number | null
  adEnergyPerformanceCertificate: string | null
  adImages: string[]
}

export type UserInformations = {
  accountState: AccountStateEnum
  monthlyRent: number
  monthlyIncomeCobuyer: number
  monthlyIncome: number
  monthlyDebt: number
  deposit: number
  postalCodeWanted: string
  contractType: string
  employmentSector: string
  publicStatus: string
  dateRange: string
  cityWanted: string
  trialPeriod: boolean
  managerialPosition: boolean
  bridgeLoan: boolean
  dob: Date
  inseePostalCode: InseePostalCode
  showFolder: boolean
  folderStatus: FolderStatusEnum | undefined
  hubspotId?: string
  notionPageId?: string
  hubspotDealId?: string
  expectedGains?: number
  loanDuration?: number
  durationInMonths?: number
  debtToIncomeRatio?: number
  processStage: UserProcessStagesEnum
  lostReason: UserLostReasonEnum
  analysisNeedDocsCustomMessage?: string
  analysisRejectionCustomMessage?: string
  analysisRejectionReason?: UserAnalaysisRejectionReasonEnum
  customBudgetOverride?: number
  owner?: {
    id: string
    email: string
  }
}

export type UserWithUserInformations = UserResponseSingleUser & {
  userInformations: UserInformations
}

export interface UserResponseSingleUser {
  deleted: boolean
  id: string
  email: string
  firstName: string
  lastName: string
  phone: string
  country: string
  verified: string
  userInformations?: UserInformations
  partnerUserInformations?: PartnerUserInformations
}

export interface UsersResponse {
  user: UserResponseSingleUser
}

export const getUserQuery = async <usersResponse>(getUserQueryInputs: GetUserQueryInputs) => {
  return (await authenticatedGetQuery(
    `/admin/users/${getUserQueryInputs.id}`,
  )) as Promise<UsersResponse>
}
// await (await fetch("http://localhost:3004/users")).json();
