import {
  Button,
  Card,
  Flex,
  ModalBody,
  ModalCloseButton,
  ModalFooter,
  ModalHeader,
  useModalContext,
} from '@chakra-ui/react'
import { Formik } from 'formik'
import React from 'react'
import TextAreaInput from '../../../../shared/components/inputs/InlineLayout/TextAreaInput'
import TextInput from '../../../../shared/components/inputs/InlineLayout/TextInput'
import ModalCustomCloseButton from './ModalCustomCloseButton'
import ModalCustomHeader from './ModalCustomHeader'
import { AssetAdStatus } from '../../../../interfaces/prisma'
import SelectInput from '../../../../shared/components/inputs/InlineLayout/SelectInput'
import { updateAssetAd, UpdateAssetAdInputs } from '../../queries/updateAssetAd'
import { AxiosError } from 'axios'
import { useMutation, useQuery, useQueryClient } from 'react-query'
import { useParams } from 'react-router-dom'
import { useCustomToast } from '../../../../utility/hooks/toats/useCustomToasts'
import { getAssetAdById } from '../../queries/getAssetAdById'
import FormikAutoRefresh from '../../../../shared/components/inputs/FormikAutoRefresh'
import {
  RejectionReasonEnum,
  rejectionReasons,
} from '../../../../shared/backShared/assetRejectionTypes'
import Regular from '../../../components/Typo/Regular'
import Bold from '../../../components/Typo/Bold'
import BooleanInput from '../../../../shared/components/inputs/InlineLayout/BooleanInput'
import AIGeneratedInput from '../../../../shared/components/inputs/InlineLayout/AIGeneratedInput'
import _ from 'lodash'
import { Asset } from '../../../leasingProjects/queries/getLeasingProjectById'
import { isNotionUrl } from '../../../../utility/urls'
import { FolderStatusEnum } from '../../../../interfaces/user'
import { UpdateUserQueryInputs, updateUserQuery } from '../../../users/queries/updateUserQuery'

type Props = {
  assetAdId?: string | undefined
  userId?: string | undefined
}

export type ValidateFolderActionValues = {
  folderStatus: FolderStatusEnum | undefined
  folderCustomMessage: boolean | undefined
}

const ValidateFolderAction = (props: Props) => {
  const { onClose } = useModalContext()
  const { errorToast, successToast } = useCustomToast()
  const queryClient = useQueryClient()

  const initialValues: ValidateFolderActionValues = {
    folderStatus: undefined,
    folderCustomMessage: undefined,
  }

  const assetAd = useQuery(['assetAd', { id: props.assetAdId }], () =>
    getAssetAdById(props.assetAdId || ''),
  )

  const updateMutation = useMutation((inputs: UpdateUserQueryInputs) => updateUserQuery(inputs), {
    onError: (error: AxiosError) => {
      errorToast(error)
    },
    onSuccess: (data) => {
      queryClient.invalidateQueries(['assetAd', { id: props.assetAdId }])
      queryClient.invalidateQueries(['user', { id: props.userId || assetAd.data?.user.id }])
      queryClient.invalidateQueries(['getFolder', { id: props.userId || assetAd.data?.user.id }])
      successToast({ description: 'All good' })
    },
  })

  return (
    <>
      <Formik
        initialValues={initialValues}
        onSubmit={async (values, formik) => {
          console.log(values)
          const payload = {
            ...values,
            id: props.userId || assetAd.data?.user.id || '',
          }
          await updateMutation.mutate(payload, {
            onSuccess: (data) => {
              formik.resetForm()
              onClose()
            },
          })
        }}
      >
        {(formik) => (
          <>
            <FormikAutoRefresh
              data={assetAd.data?.user.userInformations}
              initialValues={initialValues}
            />
            <ModalCustomCloseButton />
            <Flex padding='32px' gap='16px' direction='column'>
              <ModalCustomHeader
                title='Validation du dossier'
                subtitle='Un email sera envoyé en cas de passage de "Envoyé" à "A remplir".'
              />
              <Flex gap='24px' direction='column'>
                <SelectInput
                  label='Status'
                  field='folderStatus'
                  isRequired
                  options={[
                    { label: 'A remplir', value: FolderStatusEnum.IN_PROGRESS },
                    { label: 'Envoyé', value: FolderStatusEnum.SUBMITTED },
                    { label: 'Accepté', value: FolderStatusEnum.ACCEPTED },
                  ]}
                />

                {formik.values.folderStatus === FolderStatusEnum.IN_PROGRESS && (
                  <>
                    <AIGeneratedInput
                      field={'folderCustomMessage'}
                      label={'Message personalisé'}
                      brief="Ecris un court message qui s'affichera sur la plateforme du client qui résume pourquoi son dossier n'est pas accepté. 
                      L'objectif est qu'il remplisse de nouveau ses informations. 
                      Maximum 2 phrases.
                      La raison de refus: "
                    />
                  </>
                )}
              </Flex>
            </Flex>
            <ModalFooter>
              <Flex justifyContent='flex-end' gap='16px'>
                <Button color='HestiaGrey' onClick={onClose}>
                  Annuler
                </Button>
                <Button
                  onClick={() => formik.submitForm()}
                  type='submit'
                  background='HestiaPintGreen'
                  colorScheme='green'
                  color='white'
                  disabled={!formik.isValid}
                >
                  Sauvegarder
                </Button>
              </Flex>
            </ModalFooter>
          </>
        )}
      </Formik>
    </>
  )
}

export default ValidateFolderAction
