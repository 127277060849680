import { SimulatorHypothesis } from './getMortgageSimulatorResultsV3'
import axios from 'axios'
import { apiUrl } from '../../utility/config'
import { authenticatedGetQuery } from '../../utility/queries/authenticatedGetQuery'
import { authenticatedPostQuery } from '../../utility/queries/authenticatedPostQuery'

export interface SimulateOfferInputs {
  hypothesis: SimulatorHypothesis
  acquisitionPrice: number
  hestiaPrice: number
}
export interface SimulateOfferResponse {
  realDownPayment: number
  maximumCanSave: number
  hestiaMonthlyRent: number
  hestiaMonthlyCashback: number
  hestiaTotalCashback: number
  complementarySavingsNeeded: number
  monthlySavingsNeeded: number
  totalMonthlyExpenses: number
  hestiaDebtToIncomeRatio: number
  isTRIok: boolean
  isResilienceOk: boolean
  monthlyPaymentsForFutureLoan: number
  extraRent: number
  isDownPaymentPayableWithSavings: boolean
  rentalYieldNeededForResilience: number
  futureDeposit: number
  futureDepositNeeded: number
  downPaymentMissing: number
}

export const simulateOfferQuery = async (
  inputs: SimulateOfferInputs,
): Promise<SimulateOfferResponse> => {
  const { hypothesis, ...rest } = inputs
  return await authenticatedPostQuery('/admin/users/simulateoffer', {
    hypothesis: {
      ...Object.entries(hypothesis).reduce(
        (acc, [key, value]) => ({
          ...acc,
          [key]: typeof value === 'string' ? parseFloat(value) : value,
        }),
        {},
      ),
    },
    ...rest,
  }).then((data) => data as SimulateOfferResponse)
}
