import { CheckIcon } from '@chakra-ui/icons'
import { Box, Flex, Spinner, Textarea } from '@chakra-ui/react'
import { ArrowsInSimple, ArrowsOutSimple, Note } from '@phosphor-icons/react'
import { AxiosError } from 'axios'
import dayjs from 'dayjs'
import React, { useRef, useState } from 'react'
import { useMutation, useQuery, useQueryClient } from 'react-query'
import { useParams } from 'react-router'
import { useDebouncedCallback } from 'use-debounce'
import { HESTIA_COLORS } from '../../../../App'
import Regular from '../../../../dashboard/components/Typo/Regular'
import {
  getDiscoveryMeta,
  updateDiscoveryMeta,
  UpdateDiscoveryMetaPayload,
} from '../../../../dashboard/users/user/discovery/queries/DiscoveryMetaQueries'
import { useCustomToast } from '../../../../utility/hooks/toats/useCustomToasts'
import { countWords } from '../../../../utility/other'
import CustomBadge from '../../CustomBadge'

const StickyNotepad = () => {
  const { errorToast, successToast } = useCustomToast()
  const queryClient = useQueryClient()
  const [isOpen, setIsOpen] = useState(true)
  const [notes, setNotes] = useState('')
  const [lastSavedAt, setLastSavedAt] = useState<Date>(dayjs().toDate())
  const selectionRef = useRef(null)
  const { id: userId } = useParams()

  const metaInformations = useQuery(
    ['discoveryMeta', { id: userId }],
    () => getDiscoveryMeta(userId || ''),
    {
      onSuccess: (data) => {
        setNotes(data.notes || '')
      },
    },
  )

  const updateMutation = useMutation(
    (inputs: UpdateDiscoveryMetaPayload) => updateDiscoveryMeta(userId || '', inputs),
    {
      onError: (error: AxiosError) => {
        errorToast(error)
      },
      onSuccess: (data) => {
        // queryClient.invalidateQueries(['discoveryMeta'])
      },
    },
  )

  const handleToggle = () => setIsOpen(!isOpen)

  const onSaveToServer = async () => {
    console.log('saving to server')
    setLastSavedAt(dayjs().toDate())
    updateMutation.mutate({ notes })
  }

  const saveToServerDebounced = useDebouncedCallback(onSaveToServer, 500)

  function handleChange(value: string) {
    console.log('Change value ???', value)
    if (updateMutation.isLoading) {
      return
    }

    setNotes(value)
    saveToServerDebounced()
  }

  const getStatusMessage = () => {
    if (updateMutation.isLoading) {
      return (
        <>
          <Spinner color='#6F6F6F' w='16px' h='16px' />
          <Regular>Sauvegarde en cours</Regular>
        </>
      )
      // } else if (serverError) {
      //   return (
      //     <>
      //       <WarningTwoIcon w='16px' h='16px' color='HestiaRed' />
      //       <Regular color='HestiaRed'>Erreur: {serverError}</Regular>
      //     </>
      //   )
    } else {
      return (
        <>
          <Flex
            width='16px'
            height='16px'
            borderRadius='50%'
            border='1px solid'
            borderColor='#6F6F6F'
            justifyContent='center'
            alignItems='center'
            verticalAlign='middle'
          >
            <CheckIcon w='8px' h='8px' color='#6F6F6F' />
          </Flex>
          <Regular size='small'>
            Modifications sauvegardées à {dayjs(lastSavedAt).format('HH:mm')}
          </Regular>
        </>
      )
    }
  }

  return (
    <Box>
      <Box style={{ position: 'fixed', bottom: '24px', right: '24px', zIndex: 1000 }}>
        {/* {isOpen && ( */}
        <Box
          bg='white'
          p={6}
          borderRadius='md'
          width='33vw'
          minW='500px'
          maxH='80vh'
          // mt='8px'
          // mb='12px'
          m='8px'
          border='1px solid'
          borderColor={HESTIA_COLORS.blue[600]}
          boxShadow='lg'
          ref={selectionRef}
          overflow='scroll'
          // paddingLeft='64px'
          // paddingRight='64px'
          display={isOpen ? 'none' : 'block'}
        >
          {/* {metaInformations.isFetched && !metaInformations.isLoading && ( */}
          <Textarea
            border='none'
            height='auto'
            minH='150px'
            onChange={(e) => handleChange(e.target.value)}
            value={notes}
            // disabled={updateMutation.isLoading}
            autoFocus={true}
            // ref={textareaRef}
            // placeholder={props.placeHolder}
            // id={props.field}
            // onChange={handleInput}
            // onBlur={formik.handleBlur}
            // value={formik.values[props.field]}
            // {...props}
          />
          {/* )} */}
          <Flex justifyContent='start' alignItems='center' gap={2} w='100%'>
            {getStatusMessage()}
          </Flex>
        </Box>
        {/* )} */}
        <Flex
          onClick={handleToggle}
          bg={HESTIA_COLORS.neutral.white}
          borderRadius='8px'
          p={2}
          gap={2}
          justifyContent='space-between'
          alignItems='center'
          border='1px solid'
          borderColor={HESTIA_COLORS.blue[600]}
          boxShadow='dark-lg'
          color={HESTIA_COLORS.blue[600]}
          minW='250px'
          cursor='pointer'
          transition='all 0.3s ease-in-out'
          _hover={{ bg: HESTIA_COLORS.blue[600], color: HESTIA_COLORS.neutral.white }}
        >
          <Flex justifyContent='center' alignItems='center' gap={2}>
            <Note size={32} />
            Notes
            <CustomBadge variant='blue'>{countWords(notes)} mots</CustomBadge>
          </Flex>

          {isOpen ? (
            <ArrowsOutSimple size='32px' weight='bold' />
          ) : (
            <ArrowsInSimple size='32px' weight='bold' />
          )}
        </Flex>
      </Box>
    </Box>
  )
}

export default StickyNotepad
