import { Button, Flex, ModalFooter, useModalContext } from '@chakra-ui/react'
import { AxiosError } from 'axios'
import { Formik } from 'formik'
import React from 'react'
import { useMutation, useQuery, useQueryClient } from 'react-query'
import { useParams } from 'react-router-dom'
import {
  AssetProcessStepEnum,
  assetProcessSteps,
} from '../../../../shared/backShared/assetProcessSteps'
import FormikAutoRefresh from '../../../../shared/components/inputs/FormikAutoRefresh'
import SelectInput from '../../../../shared/components/inputs/InlineLayout/SelectInput'
import { useCustomToast } from '../../../../utility/hooks/toats/useCustomToasts'
import { getAssetAdById } from '../../queries/getAssetAdById'
import { updateAssetAd, UpdateAssetAdInputs } from '../../queries/updateAssetAd'
import ModalCustomCloseButton from './ModalCustomCloseButton'
import ModalCustomHeader from './ModalCustomHeader'

type Props = {}

export type ChangeStepActionValues = {
  newStep: AssetProcessStepEnum | undefined
}

const ChangeStepAction = (props: Props) => {
  const { onClose } = useModalContext()
  const { id } = useParams()
  const { errorToast, successToast } = useCustomToast()
  const queryClient = useQueryClient()

  const assetAd = useQuery(['assetAd', { id }], () => getAssetAdById(id!))

  const initialValues: ChangeStepActionValues = {
    newStep: assetAd.data?.processStep,
  }

  const updateMutation = useMutation((inputs: UpdateAssetAdInputs) => updateAssetAd(inputs), {
    onError: (error: AxiosError) => {
      errorToast(error)
    },
    onSuccess: (data) => {
      queryClient.invalidateQueries(['assetAd', { id }])
      successToast({ description: 'All good' })
    },
  })

  return (
    <>
      <Formik
        initialValues={initialValues}
        onSubmit={async (values, formik) => {
          console.log(values)
          await updateMutation.mutate(
            { ...values, id: id || '' },
            {
              onSuccess: (data) => {
                formik.resetForm()
                onClose()
              },
            },
          )
        }}
      >
        {(formik) => (
          <>
            <FormikAutoRefresh data={assetAd.data} initialValues={initialValues} />
            <ModalCustomCloseButton />
            <Flex padding='32px' gap='16px' direction='column'>
              <ModalCustomHeader
                title="Changer l'étape"
                subtitle="A n'utiliser qu'en cas de bug ou d'étape en cours de développement"
              />
              <Flex gap='24px' direction='column'>
                <SelectInput
                  label='Etape'
                  field='newStep'
                  isRequired
                  options={[
                    {
                      label: assetProcessSteps[AssetProcessStepEnum.ANALYSIS].label,
                      value: AssetProcessStepEnum.ANALYSIS,
                    },
                    {
                      label: assetProcessSteps[AssetProcessStepEnum.SEND_USER_OFFER].label,
                      value: AssetProcessStepEnum.SEND_USER_OFFER,
                    },
                    {
                      label: assetProcessSteps[AssetProcessStepEnum.USER_ANSWERED_OFFER].label,
                      value: AssetProcessStepEnum.USER_ANSWERED_OFFER,
                    },
                    {
                      label: assetProcessSteps[AssetProcessStepEnum.FOLDER_SENT].label,
                      value: AssetProcessStepEnum.FOLDER_SENT,
                    },
                    {
                      label: assetProcessSteps[AssetProcessStepEnum.FOLDER_ACCEPTED].label,
                      value: AssetProcessStepEnum.FOLDER_ACCEPTED,
                    },
                    {
                      label: assetProcessSteps[AssetProcessStepEnum.NEGOTIATION].label,
                      value: AssetProcessStepEnum.NEGOTIATION,
                    },
                    {
                      label: assetProcessSteps[AssetProcessStepEnum.SEND_PURCHASE_OFFER].label,
                      value: AssetProcessStepEnum.SEND_PURCHASE_OFFER,
                    },
                    {
                      label: assetProcessSteps[AssetProcessStepEnum.SIGNED_PURCHASE_OFFER].label,
                      value: AssetProcessStepEnum.SIGNED_PURCHASE_OFFER,
                    },
                    {
                      label: assetProcessSteps[AssetProcessStepEnum.SEND_USER_CONTRACT].label,
                      value: AssetProcessStepEnum.SEND_USER_CONTRACT,
                    },
                    {
                      label: assetProcessSteps[AssetProcessStepEnum.SIGNED_USER_CONTRACT].label,
                      value: AssetProcessStepEnum.SIGNED_USER_CONTRACT,
                    },
                    {
                      label: assetProcessSteps[AssetProcessStepEnum.DOWN_PAYMENT].label,
                      value: AssetProcessStepEnum.DOWN_PAYMENT,
                    },
                    {
                      label: assetProcessSteps[AssetProcessStepEnum.VISIT_BOOKING].label,
                      value: AssetProcessStepEnum.VISIT_BOOKING,
                    },
                    {
                      label: assetProcessSteps[AssetProcessStepEnum.VISIT_SUMMARY_RECEIVED].label,
                      value: AssetProcessStepEnum.VISIT_SUMMARY_RECEIVED,
                    },
                    {
                      label: assetProcessSteps[AssetProcessStepEnum.VISIT_SUMMARY_REVIEWED].label,
                      value: AssetProcessStepEnum.VISIT_SUMMARY_REVIEWED,
                    },
                    {
                      label: assetProcessSteps[AssetProcessStepEnum.NOTARY_CONNECTION].label,
                      value: AssetProcessStepEnum.NOTARY_CONNECTION,
                    },
                    {
                      label: assetProcessSteps[AssetProcessStepEnum.NOTARY_AUDIT_REQUEST].label,
                      value: AssetProcessStepEnum.NOTARY_AUDIT_REQUEST,
                    },
                    {
                      label: assetProcessSteps[AssetProcessStepEnum.NOTARY_AUDIT_RECEIVED].label,
                      value: AssetProcessStepEnum.NOTARY_AUDIT_RECEIVED,
                    },
                    {
                      label: assetProcessSteps[AssetProcessStepEnum.NOTARY_AUDIT_REVIEWED].label,
                      value: AssetProcessStepEnum.NOTARY_AUDIT_REVIEWED,
                    },
                    {
                      label: assetProcessSteps[AssetProcessStepEnum.PROMISE_WRITING].label,
                      value: AssetProcessStepEnum.PROMISE_WRITING,
                    },
                    {
                      label: assetProcessSteps[AssetProcessStepEnum.PROMISE_RECEIVED].label,
                      value: AssetProcessStepEnum.PROMISE_RECEIVED,
                    },
                    {
                      label: assetProcessSteps[AssetProcessStepEnum.PROMISE_DATE_FIXING].label,
                      value: AssetProcessStepEnum.PROMISE_DATE_FIXING,
                    },
                    {
                      label: assetProcessSteps[AssetProcessStepEnum.PROMISE_SIGNING].label,
                      value: AssetProcessStepEnum.PROMISE_SIGNING,
                    },
                  ]}
                />
              </Flex>
            </Flex>
            <ModalFooter>
              <Flex justifyContent='flex-end' gap='16px'>
                <Button color='HestiaGrey' onClick={onClose}>
                  Annuler
                </Button>
                <Button
                  onClick={() => formik.submitForm()}
                  type='submit'
                  background='HestiaPintGreen'
                  colorScheme='green'
                  color='white'
                >
                  Sauvegarder
                </Button>
              </Flex>
            </ModalFooter>
          </>
        )}
      </Formik>
    </>
  )
}

export default ChangeStepAction
