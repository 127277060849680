import { CheckIcon, CloseIcon } from '@chakra-ui/icons'
import {
  Box,
  Button,
  Flex,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  useDisclosure,
} from '@chakra-ui/react'
import React from 'react'
import { getPrettyNumber } from '../../utility/money'
import Bold from './Typo/Bold'
import HelperSmall from './Typo/HelperSmall'
import Regular from './Typo/Regular'
import RegularLink from './Typo/RegularLink'

type Props = {
  isTRIok?: boolean
  isResilienceOk?: boolean
  isDownPaymentPayableWithSavings?: boolean
  rentalYieldNeededForResilience?: number
  downPaymentMissing?: number
  actualBudget?: number
  acquisitionPrice?: number
}

const BudgetChecks = (props: Props) => {
  const isError =
    props.isDownPaymentPayableWithSavings &&
    props.isTRIok &&
    props.isResilienceOk &&
    Number(props.actualBudget) > Number(props.acquisitionPrice)

  const { isOpen, onOpen, onClose } = useDisclosure()
  return (
    <Box
      p='24px'
      borderWidth='1px'
      borderColor={isError ? 'HestiaPintGreen' : 'HestiaRed'}
      bg={isError ? 'HestiaLightGreen' : 'HestiaLightRed'}
      borderRadius='md'
      shadow='sm'
    >
      <Modal isOpen={isOpen} onClose={onClose} size='3xl'>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Règles du TRI</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Flex border='1px solid rgba(0, 0, 0, 0.1);'>
              <iframe
                width='800'
                height='700'
                src='https://www.figma.com/embed?embed_host=share&url=https%3A%2F%2Fwww.figma.com%2Ffile%2FYtWratLhoS5YsHjLaHhzXX%2FTRI-BO%3Fnode-id%3D0%253A1%26t%3DqqP2gH4fDXQey6tq-1'
                allowFullScreen
              ></iframe>
            </Flex>
          </ModalBody>

          <ModalFooter>
            <Button
              colorScheme='blue'
              mr={3}
              onClick={() =>
                window.open(
                  // `https://www.google.com/maps/dir//@${assetAd.data?.address.lat},${assetAd.data?.address.lng}`,
                  // https://www.google.com/maps/@?api=1&map_action=pano&viewpoint=48.857832%2C2.295226
                  `https://www.figma.com/file/YtWratLhoS5YsHjLaHhzXX/TRI-BO?node-id=0%3A1&t=qqP2gH4fDXQey6tq-1`,
                  '_blank',
                  'noopener,noreferrer',
                )
              }
            >
              Accéder au figjam
            </Button>
            <Button variant='ghost' onClick={onClose}>
              Fermer
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
      <Flex direction='column' gap='8px'>
        <Flex justifyContent='space-between' w='100%' gap='24px'>
          <Regular>
            TRI -{' '}
            <RegularLink as='span' onClick={onOpen}>
              Voir les règles
            </RegularLink>
          </Regular>
          <Bold>
            {props.isTRIok ? (
              <CheckIcon color='HestiaPintGreen' boxSize={`16px`} />
            ) : (
              <CloseIcon boxSize='16px' color='HestiaRed' />
            )}
          </Bold>
        </Flex>
        <Flex justifyContent='space-between' w='100%' gap='24px'>
          <Regular>Résilience</Regular>
          {props.isResilienceOk ? (
            <CheckIcon color='HestiaPintGreen' boxSize={`16px`} />
          ) : (
            <CloseIcon boxSize='16px' color='HestiaRed' />
          )}
        </Flex>
        {!props.isDownPaymentPayableWithSavings && (
          <HelperSmall>
            Rendement locatif cible de{' '}
            <b>{(Number(props.rentalYieldNeededForResilience) * 100).toFixed(2)}%</b>
          </HelperSmall>
        )}
        <Flex justifyContent='space-between' w='100%' gap='24px'>
          <Regular>Apport payable avec son épargne actuelle</Regular>
          {props.isDownPaymentPayableWithSavings ? (
            <CheckIcon color='HestiaPintGreen' boxSize={`16px`} />
          ) : (
            <CloseIcon boxSize='16px' color='HestiaRed' />
          )}
        </Flex>
        {!Number(props.isDownPaymentPayableWithSavings) && (
          <HelperSmall>
            Il manque <b>{getPrettyNumber(Math.abs(Number(props.downPaymentMissing)))}</b>
          </HelperSmall>
        )}
        <Flex justifyContent='space-between' w='100%' gap='24px'>
          <Regular>En accord avec son budget</Regular>
          {Number(props.actualBudget) > Number(props.acquisitionPrice) ? (
            <CheckIcon color='HestiaPintGreen' boxSize={`16px`} />
          ) : (
            <CloseIcon boxSize='16px' color='HestiaRed' />
          )}
        </Flex>
      </Flex>
    </Box>
  )
}

export default BudgetChecks
