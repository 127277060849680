import {
  Box,
  Button,
  Card,
  Divider,
  Flex,
  ModalFooter,
  Tooltip,
  useModalContext,
} from '@chakra-ui/react'
import { AxiosError } from 'axios'
import { Formik, FormikProps } from 'formik'
import React from 'react'
import { useMutation, useQuery, useQueryClient } from 'react-query'
import { useParams } from 'react-router-dom'
import { useDebouncedCallback } from 'use-debounce'
import { AssetAdStatus } from '../../../../interfaces/prisma'
import ConfirmButton from '../../../../shared/components/ConfirmButton'
import FormikAutoRefresh from '../../../../shared/components/inputs/FormikAutoRefresh'
import FormikOnChange from '../../../../shared/components/inputs/FormikOnChange'
import SelectInput from '../../../../shared/components/inputs/InlineLayout/SelectInput'
import TextInput from '../../../../shared/components/inputs/InlineLayout/TextInput'
import { getAssetAdStatus } from '../../../../utility/assetAds.utils'
import { useCustomToast } from '../../../../utility/hooks/toats/useCustomToasts'
import { getPrettyNumber } from '../../../../utility/money'
import {
  getMortgageSimulatorResultsV3,
  SimulatorHypothesis,
} from '../../../commonQueries/getMortgageSimulatorResultsV3'
import { SimulateOfferInputs, simulateOfferQuery } from '../../../commonQueries/simulateOfferQuery'
import BudgetChecks from '../../../components/BudgetChecks'
import Bold from '../../../components/Typo/Bold'
import Header from '../../../components/Typo/Header'
import HelperSmall from '../../../components/Typo/HelperSmall'
import Regular from '../../../components/Typo/Regular'
import { getAssetAdById } from '../../queries/getAssetAdById'
import { updateAssetAd, UpdateAssetAdInputs } from '../../queries/updateAssetAd'
import AssetIncreaseVsHestiaPriceChart from '../AssetIncreaseVsHestiaPriceChart'
import RentalYieldvsRentChart from '../RentalYieldvsRentChart'
import ModalCustomCloseButton from './ModalCustomCloseButton'
import ModalCustomHeader from './ModalCustomHeader'

type Props = {}

export type SendUserOfferValues = {
  acquisitionPrice: string
  hestiaPrice: string
  rentPrice: string
  depositPercent: string
  optionYears: number
  cashbackOnRent: number
  rentalYield: number
  assetIncrease: string
  sendOfferEmail: boolean
}

const SendUserOffer = (props: Props) => {
  const { onClose } = useModalContext()
  const { id } = useParams()
  const { errorToast, successToast } = useCustomToast()
  const queryClient = useQueryClient()

  const assetAd = useQuery(['assetAd', { id }], () => getAssetAdById(id!))

  const simulatorResults = useMutation(
    ['getMortgageSimulatorresults', 'v3'],
    (hypothesis: SimulatorHypothesis) => getMortgageSimulatorResultsV3(hypothesis),
  )
  const simulatorDefaultResults = useMutation(
    ['getMortgageSimulatorresults', 'v3'],
    (hypothesis: SimulatorHypothesis) => getMortgageSimulatorResultsV3(hypothesis),
  )

  const simulateOffer = useMutation(['simulateOffer'], (inputs: SimulateOfferInputs) =>
    simulateOfferQuery(inputs),
  )
  const simulatorResultsDebounced = useDebouncedCallback(simulatorResults.mutate, 500)
  const simulateOfferDebounced = useDebouncedCallback(simulateOffer.mutate, 500)
  const simulatorDefaultResultsDebounced = useDebouncedCallback(simulatorDefaultResults.mutate, 500)

  const initialValues: SendUserOfferValues = {
    acquisitionPrice: '0',
    hestiaPrice: '0',
    rentPrice: '0',
    depositPercent: '6',
    optionYears: 3,
    cashbackOnRent: 20,
    rentalYield: 6,
    assetIncrease: '13',
    sendOfferEmail: false,
  }

  const updateMutation = useMutation((inputs: UpdateAssetAdInputs) => updateAssetAd(inputs), {
    onError: (error: AxiosError) => {
      errorToast(error)
    },
    onSuccess: (data) => {
      queryClient.invalidateQueries(['assetAd', { id }])
      successToast({ description: 'All good' })
    },
  })

  const handleOnChange = (formik: FormikProps<SendUserOfferValues>) => {
    console.log(formik.values)

    formik.setFieldValue(
      'rentPrice',
      (((formik.values.rentalYield / 100) * Number(formik.values.acquisitionPrice)) / 12).toFixed(
        0,
      ),
    )

    formik.setFieldValue(
      'hestiaPrice',
      (
        (Number(formik.values.assetIncrease) / 100) * Number(formik.values.acquisitionPrice) +
        Number(formik.values.acquisitionPrice)
      ).toFixed(0),
    )

    const hypothesis = {
      savingsAvailable:
        (assetAd.data?.user.userInformations?.deposit || 0) +
        (assetAd.data?.user.userInformations?.expectedGains || 0),
      monthlyIncome:
        (assetAd.data?.user.userInformations?.monthlyIncome || 0) +
        (assetAd.data?.user.userInformations?.monthlyIncomeCobuyer || 0),
      monthlyDebt: assetAd.data?.user.userInformations?.monthlyDebt || 0,
      rentalYield: formik.values.rentalYield,
      cashbackPercentage: formik.values.cashbackOnRent,
      assetIncrease: formik.values.assetIncrease,
      offerDownpayment: formik.values.depositPercent,
      optionYears: formik.values.optionYears,
    }

    simulatorResultsDebounced({ ...hypothesis })
    simulateOfferDebounced({
      hypothesis: hypothesis,
      acquisitionPrice: Number(formik.values.acquisitionPrice),
      hestiaPrice: Number(formik.values.hestiaPrice),
    })
    simulatorDefaultResultsDebounced({
      ...hypothesis,
      rentalYield: undefined,
      cashbackPercentage: undefined,
      assetIncrease: undefined,
      offerDownpayment: undefined,
    })
  }

  return (
    <>
      <Formik
        initialValues={initialValues}
        onSubmit={async (values, formik) => {
          console.log(values)
          await updateMutation.mutate(
            {
              acquisitionPrice: parseFloat(values.acquisitionPrice),
              hestiaPrice: parseFloat(values.hestiaPrice),
              rentPrice: parseFloat(values.rentPrice),
              depositPercent: parseFloat(values.depositPercent),
              optionYears: values.optionYears,
              cashbackOnRent: values.cashbackOnRent,
              sendOfferEmail: values.sendOfferEmail,
              // assetIncrease: values.assetIncrease,
              // offerDownpayment: values.offerDownpayment,
              id: id || '',
            },
            {
              onSuccess: (data) => {
                formik.resetForm()
                onClose()
              },
            },
          )
        }}
      >
        {(formik) => (
          <>
            <FormikOnChange onChange={() => handleOnChange(formik)} />
            <FormikAutoRefresh
              data={{
                // ...formik.values,
                ...assetAd.data,
                assetIncrease: (
                  ((Number(assetAd.data?.hestiaPrice) - Number(assetAd.data?.acquisitionPrice)) /
                    Number(assetAd.data?.acquisitionPrice)) *
                  100
                ).toFixed(2),
                rentalYield: (
                  ((Number(assetAd.data?.rentPrice) * 12) /
                    Number(assetAd.data?.acquisitionPrice)) *
                  100
                ).toFixed(2),
                // rentalYield: (
                //   ((parseFloat(formik.values.rentPrice) * 12) /
                //     parseFloat(formik.values.hestiaPrice)) *
                //   100
                // ).toFixed(0),
              }}
              initialValues={initialValues}
            />
            <ModalCustomCloseButton />
            <Flex padding='32px' gap='16px' direction='column'>
              <ModalCustomHeader title="Proposition d'accompagnement" />
              <Flex gap='32px' direction='row' w='100%'>
                <RentalYieldvsRentChart
                  acquisitionPrice={parseFloat(formik.values.acquisitionPrice)}
                  monthlyAvailable={simulatorResults.data?.userResult.maximumCanSave || 0}
                  complementarySavingsNeeded={
                    simulatorResults.data?.userResult.monthlySavingsNeeded || 0
                  }
                />
                <AssetIncreaseVsHestiaPriceChart
                  acquisitionPrice={parseFloat(formik.values.acquisitionPrice)}
                  actualBudget={simulatorResults.data?.userResult.maximumCanBorrow || 0}
                />
                {/* <RentalYieldvsRentChart
                  acquisitionPrice={parseFloat(formik.values.acquisitionPrice)}
                  monthlyAvailable={simulatorResults.data?.userResult.maximumCanSave || 0}
                  complementarySavingsNeeded={
                    simulatorResults.data?.userResult.complementarySavingsNeeded || 0
                  }
                /> */}
              </Flex>
              <Flex gap='32px' direction='row'>
                <Flex w='40%' gap='16px' direction='column'>
                  <Flex gap='16px' direction='column'>
                    <Card padding='8px'>
                      <Flex direction='column' gap='8px'>
                        <Bold>Paramètres de l'offre</Bold>
                        <TextInput
                          size='sm'
                          label='Prix d’acquisition'
                          field='acquisitionPrice'
                          type='currency'
                        />
                        <Flex gap='8px'>
                          <TextInput
                            size='sm'
                            label='Plus value %'
                            field='assetIncrease'
                            type='percent'
                          />
                          <TextInput
                            disabled
                            size='sm'
                            label='Prix final'
                            field='hestiaPrice'
                            type='currency'
                          />
                        </Flex>
                        <Flex gap='8px'>
                          <TextInput
                            size='sm'
                            label='Rentabilité locative'
                            field='rentalYield'
                            type='percent'
                            // additionalOnChange={(value) => {
                            //   formik.setFieldValue(
                            //     'rentPrice',
                            //     ((parseFloat(value) / 100) *
                            //       parseFloat(formik.values.acquisitionPrice)) /
                            //       12,
                            //   )
                            // }}
                          />
                          <TextInput
                            disabled
                            size='sm'
                            label='Redevance mensuelle'
                            field='rentPrice'
                            type='currency'
                          />
                        </Flex>

                        <SelectInput
                          selectSize='sm'
                          label="Durée de l'option (années)"
                          field='optionYears'
                          options={[
                            { label: '3 ans', value: 3 },
                            { label: '2 ans', value: 2 },
                            { label: '1 ans', value: 1 },
                          ]}
                        />
                        <SelectInput
                          selectSize='sm'
                          label='Cashback'
                          field='cashbackOnRent'
                          options={[
                            { label: '20%', value: 20 },
                            { label: '0%', value: 0 },
                          ]}
                        />
                        <TextInput
                          size='sm'
                          label='Apport initial'
                          field='depositPercent'
                          type='percent'
                        />
                      </Flex>
                    </Card>
                  </Flex>
                  <Regular>
                    Proposition envoyée <b>{assetAd.data?.nbOffersSent}</b> fois par email.
                  </Regular>
                  <Regular>
                    La proposition est{' '}
                    <Tooltip
                      hasArrow
                      label="Pour que la proposition soit visible il faut qu'elle soit acceptée et que l'offre soit complète."
                    >
                      <Box
                        as='span'
                        color={
                          assetAd.data && getAssetAdStatus(assetAd.data) === AssetAdStatus.ACCEPTED
                            ? 'HestiaPintGreen'
                            : 'HestiaRed'
                        }
                        fontWeight='600'
                      >
                        {assetAd.data && getAssetAdStatus(assetAd.data) === AssetAdStatus.ACCEPTED
                          ? 'visible'
                          : 'non visible'}
                      </Box>
                    </Tooltip>{' '}
                    pour l'utilisateur sur la plateforme
                  </Regular>
                </Flex>
                <Flex w='67%' direction='column' gap='8px'>
                  <BudgetChecks
                    isTRIok={simulateOffer.data?.isTRIok}
                    isResilienceOk={simulateOffer.data?.isResilienceOk}
                    isDownPaymentPayableWithSavings={
                      simulateOffer.data?.isDownPaymentPayableWithSavings
                    }
                    rentalYieldNeededForResilience={
                      simulateOffer.data?.rentalYieldNeededForResilience
                    }
                    downPaymentMissing={simulateOffer.data?.downPaymentMissing}
                    actualBudget={Number(simulatorResults.data?.userResult.actualBudget)}
                    acquisitionPrice={Number(formik.values.acquisitionPrice)}
                  />
                  <Flex gap='16px'>
                    <Card w='50%' h='fit-content'>
                      <Card
                        padding='12px'
                        bg='HestiaLightGreen'
                        border='1px solid'
                        borderColor='HestiaPintGreen'
                        borderRadius='8px'
                        gap='8px'
                      >
                        <Flex justifyContent='space-between' w='100%' gap='24px'>
                          <Bold>Acquisition</Bold>
                          <Header fontSize='20px' color='Initial'>
                            {getPrettyNumber(Number(formik.values.acquisitionPrice), 0, true)}
                          </Header>
                        </Flex>
                        <Flex justifyContent='space-between' w='100%' gap='24px'>
                          <Bold>Prix de levée d'option</Bold>
                          <Header color='HestiaPintGreen'>
                            {getPrettyNumber(Number(formik.values.hestiaPrice), 0, true)}
                          </Header>
                        </Flex>
                      </Card>
                      <Flex direction='column' padding='12px' gap='8px'>
                        <Flex justifyContent='space-between' w='100%' gap='24px'>
                          <Regular>Redevance</Regular>
                          <Bold>{getPrettyNumber(Number(formik.values.rentPrice), 0, true)}</Bold>
                        </Flex>
                        <Flex justifyContent='space-between' w='100%' gap='24px'>
                          <Regular>Dont cashback</Regular>
                          <Bold>
                            {getPrettyNumber(
                              (Number(formik.values.rentPrice) *
                                Number(formik.values.cashbackOnRent)) /
                                100,
                              0,
                              true,
                            )}
                          </Bold>
                        </Flex>
                        <Flex justifyContent='space-between' w='100%' gap='24px'>
                          <Regular>Epargne complémentaire</Regular>
                          <Bold>
                            {getPrettyNumber(simulateOffer.data?.monthlySavingsNeeded, 0, true)}
                          </Bold>
                        </Flex>
                        <Flex justifyContent='space-between' w='100%' gap='24px'>
                          <Regular>Pression sur salaire</Regular>
                          <Bold
                            color={
                              Number(simulateOffer.data?.hestiaDebtToIncomeRatio) > 0.35
                                ? 'HestiaRed'
                                : 'initial'
                            }
                          >
                            {(Number(simulateOffer.data?.hestiaDebtToIncomeRatio) * 100).toFixed(0)}
                            %
                          </Bold>
                        </Flex>
                      </Flex>
                    </Card>
                    <Card w='50%' h='fit-content'>
                      <Card
                        padding='12px'
                        bg='HestiaLightBlue'
                        border='1px solid'
                        borderColor='HestiaBlue'
                        borderRadius='8px'
                        gap='8px'
                      >
                        <Flex justifyContent='space-between' w='100%' gap='24px'>
                          <Flex direction='column'>
                            <Bold>Budget</Bold>
                            <HelperSmall>Communiqué</HelperSmall>
                          </Flex>

                          <Header color='HestiaBlue'>
                            {getPrettyNumber(
                              Number(simulatorDefaultResults.data?.userResult.actualBudget),
                              0,
                              true,
                            )}
                          </Header>
                        </Flex>
                        <Divider />
                        <Flex justifyContent='space-between' w='100%' gap='24px'>
                          <Flex direction='column'>
                            <Bold>Budget</Bold>
                            <HelperSmall>Avec ces paramètres</HelperSmall>
                          </Flex>
                          <Header color='HestiaBlue'>
                            {getPrettyNumber(
                              Number(simulatorResults.data?.userResult.actualBudget),
                              0,
                              true,
                            )}
                          </Header>
                        </Flex>
                      </Card>
                      <Flex direction='column' padding='12px' gap='8px'>
                        <Flex justifyContent='space-between' w='100%' gap='24px'>
                          <Regular>Apport disponible</Regular>
                          <Bold>
                            {getPrettyNumber(
                              assetAd.data?.user.userInformations?.deposit || 0,
                              0,
                              true,
                            )}
                          </Bold>
                        </Flex>
                        <Flex justifyContent='space-between' w='100%' gap='24px'>
                          <Regular>Revenus mensuels</Regular>
                          <Bold>
                            {getPrettyNumber(
                              (assetAd.data?.user.userInformations?.monthlyIncome || 0) +
                                (assetAd.data?.user.userInformations?.monthlyIncomeCobuyer || 0),
                              0,
                              true,
                            )}
                          </Bold>
                        </Flex>
                        <Flex justifyContent='space-between' w='100%' gap='24px'>
                          <Regular>Crédits mensuels</Regular>
                          <Bold>
                            {getPrettyNumber(
                              assetAd.data?.user.userInformations?.monthlyDebt || 0,
                              0,
                              true,
                            )}
                          </Bold>
                        </Flex>
                        <Divider />
                        <Flex justifyContent='space-between' w='100%' gap='24px'>
                          <Regular>Prix max de levée</Regular>
                          <Bold>
                            {getPrettyNumber(
                              Number(simulatorResults.data?.userResult.maximumCanBorrow),
                              0,
                              true,
                            )}
                          </Bold>
                        </Flex>
                      </Flex>
                    </Card>
                  </Flex>
                  {/* <OfferSummary
                    cta={false}
                    sellPrice={parseFloat(formik.values.hestiaPrice) || 0}
                    rentPrice={parseFloat(formik.values.rentPrice) || 0}
                    cashbackOnRent={parseFloat(formik.values.cashbackOnRent) || 0}
                    depositPercent={parseFloat(formik.values.depositPercent) || 0}
                    optionYears={parseFloat(formik.values.optionYears) || 0}
                  /> */}
                </Flex>
              </Flex>
            </Flex>
            <ModalFooter>
              <Flex justifyContent='flex-end' gap='16px'>
                <Button color='HestiaGrey' onClick={onClose}>
                  Annuler
                </Button>
                <Button
                  onClick={() => {
                    formik.setFieldValue('sendOfferEmail', false)
                    formik.submitForm()
                  }}
                  type='submit'
                  colorScheme='green'
                  variant='outline'
                >
                  Enregistrer
                </Button>
                <ConfirmButton
                  bodyText='Cette action va envoyer un mail à un utilisateur'
                  buttonText={'Enregistrer et envoyer un email'}
                  headerText='Cette action va envoyer un email'
                  onSuccessAction={() => {
                    formik.setFieldValue('sendOfferEmail', true)
                    formik.submitForm()
                  }}
                />
              </Flex>
            </ModalFooter>
          </>
        )}
      </Formik>
    </>
  )
}

export default SendUserOffer
