import {
  Button,
  Card,
  Flex,
  ModalBody,
  ModalCloseButton,
  ModalFooter,
  ModalHeader,
  useModalContext,
} from '@chakra-ui/react'
import { Formik } from 'formik'
import React from 'react'
import TextAreaInput from '../../../../shared/components/inputs/InlineLayout/TextAreaInput'
import TextInput from '../../../../shared/components/inputs/InlineLayout/TextInput'
import ModalCustomCloseButton from './ModalCustomCloseButton'
import ModalCustomHeader from './ModalCustomHeader'
import { AssetAdStatus } from '../../../../interfaces/prisma'
import SelectInput from '../../../../shared/components/inputs/InlineLayout/SelectInput'
import { updateAssetAd, UpdateAssetAdInputs } from '../../queries/updateAssetAd'
import { AxiosError } from 'axios'
import { useMutation, useQuery, useQueryClient } from 'react-query'
import { useParams } from 'react-router-dom'
import { useCustomToast } from '../../../../utility/hooks/toats/useCustomToasts'
import { getAssetAdById } from '../../queries/getAssetAdById'
import FormikAutoRefresh from '../../../../shared/components/inputs/FormikAutoRefresh'
import {
  RejectionReasonEnum,
  rejectionReasons,
} from '../../../../shared/backShared/assetRejectionTypes'
import Regular from '../../../components/Typo/Regular'
import Bold from '../../../components/Typo/Bold'
import BooleanInput from '../../../../shared/components/inputs/InlineLayout/BooleanInput'
import AIGeneratedInput from '../../../../shared/components/inputs/InlineLayout/AIGeneratedInput'
import _ from 'lodash'
import { Asset } from '../../../leasingProjects/queries/getLeasingProjectById'
import { isNotionUrl } from '../../../../utility/urls'
import { validateAnalysisAction } from '../validations/validateAnalysisAction'

type Props = {}

export type AnalysisActionValues = {
  hestiaStatus: AssetAdStatus | undefined
  rejectionReason: RejectionReasonEnum | undefined
  customMessage: boolean | undefined
  rejectionMessage: string | undefined
  notionUrl: string | undefined
}

const AnalysisAction = (props: Props) => {
  const { onClose } = useModalContext()
  const { id } = useParams()
  const { errorToast, successToast } = useCustomToast()
  const queryClient = useQueryClient()

  const initialValues: AnalysisActionValues = {
    hestiaStatus: undefined,
    rejectionReason: undefined,
    customMessage: undefined,
    rejectionMessage: undefined,
    notionUrl: undefined,
  }

  const assetAd = useQuery(['assetAd', { id }], () => getAssetAdById(id!))

  const updateMutation = useMutation((inputs: UpdateAssetAdInputs) => updateAssetAd(inputs), {
    onError: (error: AxiosError) => {
      errorToast(error)
    },
    onSuccess: (data) => {
      queryClient.invalidateQueries(['assetAd', { id }])
      successToast({ description: 'All good' })
    },
  })

  return (
    <>
      <Formik
        initialValues={initialValues}
        validate={validateAnalysisAction}
        onSubmit={async (values, formik) => {
          console.log(values)
          const payload = {
            ..._.omit(values, ['customMessage']),
            id: id || '',
          }
          await updateMutation.mutate(payload, {
            onSuccess: (data) => {
              formik.resetForm()
              onClose()
            },
          })
        }}
      >
        {(formik) => (
          <>
            <FormikAutoRefresh data={assetAd.data} initialValues={initialValues} />
            <ModalCustomCloseButton />
            <Flex padding='32px' gap='16px' direction='column'>
              <ModalCustomHeader
                title='Analyse du bien'
                subtitle='Un email sera envoyé en cas de refus.'
              />
              <Flex gap='24px' direction='column'>
                <SelectInput
                  label='Status'
                  field='hestiaStatus'
                  isRequired
                  options={[
                    { label: 'En attente', value: AssetAdStatus.PENDING },
                    { label: 'Accepté', value: AssetAdStatus.ACCEPTED },
                    { label: 'Refusé', value: AssetAdStatus.REJECTED },
                  ]}
                />
                {formik.values.hestiaStatus === AssetAdStatus.ACCEPTED && (
                  <TextInput field={'notionUrl'} label={'URL Notion'}  />
                )}
                {formik.values.hestiaStatus === AssetAdStatus.REJECTED && (
                  <>
                    <SelectInput
                      field={'rejectionReason'}
                      label={'Raison de refus'}
                      options={rejectionReasons}
                    />
                    {formik.values.rejectionReason && (
                      <Card border='1px solid' borderColor='HestiaLightBlue' p='8px'>
                        <Flex direction='column' gap='8px'>
                          <Bold>Exemple de message qui sera envoyé avec cette raison :</Bold>
                          <Regular>
                            {
                              rejectionReasons.find(
                                (e) => e.value === formik.values.rejectionReason,
                              )?.emailContent
                            }
                          </Regular>
                        </Flex>
                      </Card>
                    )}
                    <BooleanInput
                      field={'customMessage'}
                      checkboxLabel={'Je veux envoyer un message personalisé en plus.'}
                    />
                    {formik.values.customMessage && (
                      <>
                        <AIGeneratedInput
                          field={'rejectionMessage'}
                          label={'Message personalisé'}
                        />
                      </>
                    )}
                  </>
                )}
              </Flex>
            </Flex>
            <ModalFooter>
              <Flex justifyContent='flex-end' gap='16px'>
                <Button color='HestiaGrey' onClick={onClose}>
                  Annuler
                </Button>
                <Button
                  onClick={() => formik.submitForm()}
                  type='submit'
                  background='HestiaPintGreen'
                  colorScheme='green'
                  color='white'
                  disabled={!formik.isValid}
                >
                  Sauvegarder
                </Button>
              </Flex>
            </ModalFooter>
          </>
        )}
      </Formik>
    </>
  )
}

export default AnalysisAction
