import {
  Button,
  Card,
  Flex,
  ModalBody,
  ModalCloseButton,
  ModalFooter,
  ModalHeader,
  useModalContext,
} from '@chakra-ui/react'
import { Formik } from 'formik'
import React from 'react'
import TextAreaInput from '../../../../shared/components/inputs/InlineLayout/TextAreaInput'
import TextInput from '../../../../shared/components/inputs/InlineLayout/TextInput'
import ModalCustomCloseButton from './ModalCustomCloseButton'
import ModalCustomHeader from './ModalCustomHeader'
import { AssetAdStatus } from '../../../../interfaces/prisma'
import SelectInput from '../../../../shared/components/inputs/InlineLayout/SelectInput'
import { updateAssetAd, UpdateAssetAdInputs } from '../../queries/updateAssetAd'
import { AxiosError } from 'axios'
import { useMutation, useQuery, useQueryClient } from 'react-query'
import { useParams } from 'react-router-dom'
import { useCustomToast } from '../../../../utility/hooks/toats/useCustomToasts'
import { getAssetAdById } from '../../queries/getAssetAdById'
import FormikAutoRefresh from '../../../../shared/components/inputs/FormikAutoRefresh'
import {
  RejectionReasonEnum,
  rejectionReasons,
} from '../../../../shared/backShared/assetRejectionTypes'
import Regular from '../../../components/Typo/Regular'
import Bold from '../../../components/Typo/Bold'
import BooleanInput from '../../../../shared/components/inputs/InlineLayout/BooleanInput'
import AIGeneratedInput from '../../../../shared/components/inputs/InlineLayout/AIGeneratedInput'
import _ from 'lodash'
import { Asset } from '../../../leasingProjects/queries/getLeasingProjectById'
import { isNotionUrl } from '../../../../utility/urls'
import { FolderStatusEnum } from '../../../../interfaces/user'
import { UpdateUserQueryInputs, updateUserQuery } from '../../../users/queries/updateUserQuery'

type Props = {}

const SendFolderAction = (props: Props) => {
  const { onClose } = useModalContext()
  const { id } = useParams()
  const { errorToast, successToast } = useCustomToast()
  const queryClient = useQueryClient()

  const assetAd = useQuery(['assetAd', { id }], () => getAssetAdById(id!))

  return (
    <>
      <ModalCustomCloseButton />
      <Flex padding='32px' gap='16px' direction='column'>
        <ModalCustomHeader
          title='Envoi du dossier'
          subtitle='Pour continuer, il faut que le client envoie son dossier.'
        />
      </Flex>
      <ModalFooter>
        <Flex justifyContent='flex-end' gap='16px'>
          <Button color='HestiaGrey' onClick={onClose}>
            Annuler
          </Button>
          {/* <Button type='submit' background='HestiaPintGreen' colorScheme='green' color='white'>
            Sauvegarder
          </Button> */}
        </Flex>
      </ModalFooter>
    </>
  )
}

export default SendFolderAction
