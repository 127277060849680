import {
  Alert,
  Button,
  Flex,
  ModalBody,
  ModalCloseButton,
  ModalFooter,
  ModalHeader,
  useModalContext,
} from '@chakra-ui/react'
import { Formik } from 'formik'
import React from 'react'
import TextAreaInput from '../../../../shared/components/inputs/InlineLayout/TextAreaInput'
import TextInput from '../../../../shared/components/inputs/InlineLayout/TextInput'
import ModalCustomCloseButton from './ModalCustomCloseButton'
import ModalCustomHeader from './ModalCustomHeader'
import { AssetAdStatus } from '../../../../interfaces/prisma'
import SelectInput from '../../../../shared/components/inputs/InlineLayout/SelectInput'
import { updateAssetAd, UpdateAssetAdInputs } from '../../queries/updateAssetAd'
import { AxiosError } from 'axios'
import { useMutation, useQuery, useQueryClient } from 'react-query'
import { useParams } from 'react-router-dom'
import { useCustomToast } from '../../../../utility/hooks/toats/useCustomToasts'
import { getAssetAdById } from '../../queries/getAssetAdById'
import FormikAutoRefresh from '../../../../shared/components/inputs/FormikAutoRefresh'

type Props = {}

export type UserOfferResponseActionValues = {
  userStatus: AssetAdStatus | undefined
}

const UserOfferResponseAction = (props: Props) => {
  const { onClose } = useModalContext()
  const { id } = useParams()
  const { errorToast, successToast } = useCustomToast()
  const queryClient = useQueryClient()

  const initialValues: UserOfferResponseActionValues = {
    userStatus: undefined,
  }

  const assetAd = useQuery(['assetAd', { id }], () => getAssetAdById(id!))

  const updateMutation = useMutation((inputs: UpdateAssetAdInputs) => updateAssetAd(inputs), {
    onError: (error: AxiosError) => {
      errorToast(error)
    },
    onSuccess: (data) => {
      queryClient.invalidateQueries(['assetAd', { id }])
      successToast({ description: 'All good' })
    },
  })

  return (
    <>
      <Formik
        initialValues={initialValues}
        onSubmit={async (values, formik) => {
          console.log(values)
          await updateMutation.mutate(
            { ...values, id: id || '' },
            {
              onSuccess: (data) => {
                formik.resetForm()
                onClose()
              },
            },
          )
        }}
      >
        {(formik) => (
          <>
            <FormikAutoRefresh data={assetAd.data} initialValues={initialValues} />
            <ModalCustomCloseButton />
            <Flex padding='32px' gap='16px' direction='column'>
              <ModalCustomHeader
                title="Réponse de l'utilisateur"
                subtitle={
                  <>
                    Cette étape sera mise automatiquement à jour quand le client acceptera ou
                    refusera l'offre.
                  </>
                }
              />
              <Alert borderRadius='8px'>
                Vous pouvez également remplir sa réponse à sa place pour continuer.
              </Alert>
              <Flex gap='24px' direction='column'>
                <SelectInput
                  label='Status'
                  field='userStatus'
                  isRequired
                  options={[
                    { label: 'En attente', value: AssetAdStatus.PENDING },
                    { label: 'Accepté', value: AssetAdStatus.ACCEPTED },
                    { label: 'Refusé', value: AssetAdStatus.REJECTED },
                  ]}
                />
              </Flex>
            </Flex>
            <ModalFooter>
              <Flex justifyContent='flex-end' gap='16px'>
                <Button color='HestiaGrey' onClick={onClose}>
                  Annuler
                </Button>
                <Button
                  onClick={() => formik.submitForm()}
                  type='submit'
                  background='HestiaPintGreen'
                  colorScheme='green'
                  color='white'
                >
                  Sauvegarder
                </Button>
              </Flex>
            </ModalFooter>
          </>
        )}
      </Formik>
    </>
  )
}

export default UserOfferResponseAction
