import { Card, Flex } from '@chakra-ui/react'
import _ from 'lodash'
import React from 'react'
import {
  LineChart,
  XAxis,
  YAxis,
  Label,
  Tooltip,
  CartesianGrid,
  ReferenceArea,
  ReferenceLine,
  Line,
  ResponsiveContainer,
  Legend,
} from 'recharts'
import {
  getClosestInArrayOfObject,
  getObjectWithClosestDebtToIncomeRatio,
  getPrettyNumber,
} from '../../../utility/money'
import Bold from '../../components/Typo/Bold'
import Regular from '../../components/Typo/Regular'
import { FormikValues, useFormikContext } from 'formik'
import { useState, useEffect } from 'react'

type Props = {
  acquisitionPrice: number
  actualBudget: number
}

interface ChartData {
  assetIncrease: number
  hestiaPrice: number
}

const AssetIncreaseVsHestiaPriceChart = (props: Props) => {
  const numberOfDataPoints: number = (8 - 3) / 0.05 + 1
  const formik = useFormikContext<FormikValues>()

  const chartData: ChartData[] = []

  for (let i = 3; i <= 15; i += 0.05) {
    const assetIncrease = i / 100
    const hestiaPrice = props.acquisitionPrice * assetIncrease + props.acquisitionPrice
    chartData.push({ assetIncrease, hestiaPrice })
  }

  const [actualBudgetStroke, setActualBudgetStroke] = useState(chartData[0])

  useEffect(() => {
    setActualBudgetStroke(
      getClosestInArrayOfObject(chartData, 'hestiaPrice', props.actualBudget) || chartData[0],
    )
  }, [chartData])

  const minValue = _.minBy(chartData, 'hestiaPrice')?.hestiaPrice || 0
  const maxValue = _.maxBy(chartData, 'hestiaPrice')?.hestiaPrice || 0
  return (
    <Flex direction='column' gap='8px' w='100%'>
      <Bold>Plus value</Bold>
      <ResponsiveContainer width='100%' height={250}>
        <LineChart
          onClick={(value) => {
            formik.setFieldValue('assetIncrease', (Number(value.activeLabel) * 100).toFixed(2))
          }}
          style={{
            cursor: 'pointer',
          }}
          data={chartData}
          margin={{
            top: 0,
            right: 0,
            left: 0,
            bottom: 25,
          }}
        >
          <CartesianGrid strokeDasharray='3 3' />

          <ReferenceLine
            x={
              getClosestInArrayOfObject(
                chartData,
                'assetIncrease',
                formik.values.assetIncrease / 100,
              )?.assetIncrease
            }
            stroke='blue'
            strokeWidth={2}
            fillOpacity={1}
          />
          <Legend
            payload={[
              { value: '> du budget', type: 'square', color: 'coral', id: 'ID01' },
              { value: 'Parfait', type: 'square', color: 'green', id: 'ID02' },
            ]}
          />
          <XAxis dataKey='assetIncrease' tickFormatter={(tick) => `${(tick * 100).toFixed(1)}%`} />
          <YAxis
            dataKey='hestiaPrice'
            type='number'
            tickFormatter={(tick) => `${getPrettyNumber(tick, 0, false)}`}
            domain={[minValue, maxValue]}
            width={100}
          />

          <ReferenceArea
            y1={actualBudgetStroke.hestiaPrice}
            y2={_.maxBy(chartData, 'hestiaPrice')?.hestiaPrice}
            fill='lightcoral'
            fillOpacity={0.4}
          />
          <ReferenceArea
            y1={_.minBy(chartData, 'hestiaPrice')?.hestiaPrice}
            y2={actualBudgetStroke.hestiaPrice}
            fill='lightgreen'
            fillOpacity={0.4}
          />
          <Tooltip
            content={({ active, payload, label }) => {
              if (active && payload && payload.length) {
                return (
                  <Card bg='white' padding='16px'>
                    <Flex gap='8px' direction='column'>
                      <Regular>
                        Prix de l'option:{' '}
                        <b>{`${getPrettyNumber(
                          parseFloat(payload[0].value?.toString() || '0'),
                          0,
                          false,
                        )}`}</b>
                      </Regular>
                      <Regular>
                        % de plus value: <b>{`${(parseFloat(label || '0') * 100).toFixed(2)}`}%</b>
                      </Regular>
                    </Flex>
                  </Card>
                )
              }
              return null
            }}
          />

          <Line dataKey='hestiaPrice' dot={false} animationDuration={1000} strokeWidth={2} />
          <Tooltip />
        </LineChart>
      </ResponsiveContainer>
    </Flex>
  )
}

export default AssetIncreaseVsHestiaPriceChart
