import { Badge, Button, Flex, Text, Tooltip, useDisclosure } from '@chakra-ui/react'
import dayjs from 'dayjs'
import relativeTime from 'dayjs/plugin/relativeTime'
import React from 'react'
import { BiCalendar } from 'react-icons/bi'
import { useQuery } from 'react-query'
import { useParams } from 'react-router-dom'
import {
  AssetProcessStepEnum, assetProcessSteps, getStepNumber,
  TeamsEnum
} from '../../../shared/backShared/assetProcessSteps'
import Regular from '../../components/Typo/Regular'
import { getAssetAdById } from '../queries/getAssetAdById'
import StepTodo from '../Steps/StepTodo'
import AnalysisAction from './actions/AnalysisAction'
import SendFolderAction from './actions/SendFolderAction'
import SendUserOffer from './actions/SendUserOfferAction'
import UserOfferResponseAction from './actions/UserOfferResponseAction'
import ValidateFolderAction from './actions/ValidateFolderAction'
import AssetProcessModalWrapper from './AssetProcessModalWrapper'
require('dayjs/locale/fr')
dayjs.extend(relativeTime)
dayjs.locale('fr')

type Props = {
  currentStep: AssetProcessStepEnum
  step: AssetProcessStepEnum
}

const AssetProcessStepTodo = (props: Props) => {
  const current = getStepNumber(props.currentStep) === getStepNumber(props.step)
  const { isOpen, onOpen, onClose } = useDisclosure()
  const { id } = useParams()
  const assetAd = useQuery(['assetAd', { id }], () => getAssetAdById(id!))

  const getTeamBadge = (team: TeamsEnum) => {
    return team === TeamsEnum.SUPPLY ? (
      <Badge colorScheme='yellow'>Analyst</Badge>
    ) : (
      <Badge colorScheme='blue'>Sales</Badge>
    )
  }

  const getCurrentAction = (step: AssetProcessStepEnum) => {
    switch (step) {
      case AssetProcessStepEnum.ANALYSIS:
        return (
          <AssetProcessModalWrapper isOpen={isOpen} onClose={onClose} size='xl'>
            <AnalysisAction />
          </AssetProcessModalWrapper>
        )
      case AssetProcessStepEnum.SEND_USER_OFFER:
        return (
          <AssetProcessModalWrapper isOpen={isOpen} onClose={onClose} size='6xl' maxW='90vw'>
            <SendUserOffer />
          </AssetProcessModalWrapper>
        )
      case AssetProcessStepEnum.USER_ANSWERED_OFFER:
        return (
          <AssetProcessModalWrapper isOpen={isOpen} onClose={onClose}>
            <UserOfferResponseAction />
          </AssetProcessModalWrapper>
        )
      case AssetProcessStepEnum.FOLDER_SENT:
        return (
          <AssetProcessModalWrapper isOpen={isOpen} onClose={onClose}>
            <SendFolderAction />
          </AssetProcessModalWrapper>
        )
      case AssetProcessStepEnum.FOLDER_ACCEPTED:
        return (
          <AssetProcessModalWrapper isOpen={isOpen} onClose={onClose}>
            <ValidateFolderAction assetAdId={id} />
          </AssetProcessModalWrapper>
        )
      // case AssetProcessStepEnum.NEGOTIATION:
      //   return (
      //     <AssetProcessModalWrapper isOpen={isOpen} onClose={onClose}>
      //       <NegociationAction />
      //     </AssetProcessModalWrapper>
      //   )
      // case AssetProcessStepEnum.SEND_PURCHASE_OFFER:
      //   return (
      //     <AssetProcessModalWrapper isOpen={isOpen} onClose={onClose}>
      //       <SendPurchaseOffer />
      //     </AssetProcessModalWrapper>
      //   )
      // case AssetProcessStepEnum.SIGNED_PURCHASE_OFFER:
      //   return (
      //     <AssetProcessModalWrapper isOpen={isOpen} onClose={onClose}>
      //       <SignPurchaseOfferAction />
      //     </AssetProcessModalWrapper>
      //   )
      // case AssetProcessStepEnum.SEND_USER_CONTRACT:
      //   return (
      //     <AssetProcessModalWrapper isOpen={isOpen} onClose={onClose}>
      //       <SendUserContractAction />
      //     </AssetProcessModalWrapper>
      //   )
      // case AssetProcessStepEnum.SIGNED_USER_CONTRACT:
      //   return (
      //     <AssetProcessModalWrapper isOpen={isOpen} onClose={onClose}>
      //       <SignUserContractAction />
      //     </AssetProcessModalWrapper>
      //   )
      // case AssetProcessStepEnum.DOWN_PAYMENT:
      //   return (
      //     <AssetProcessModalWrapper isOpen={isOpen} onClose={onClose}>
      //       <ReceivedUserDownpaymentAction />
      //     </AssetProcessModalWrapper>
      //   )
      default:
        return (
          <AssetProcessModalWrapper isOpen={isOpen} onClose={onClose}>
            Pas encore développé !
          </AssetProcessModalWrapper>
        )
    }
  }

  const currentProcessStepData = assetAd.data?.assetProcessSteps.find((s) => s.step === props.step)

  console.log(currentProcessStepData)

  return (
    <>
      {getCurrentAction(props.step)}

      <StepTodo
        text={assetProcessSteps[props.step].label}
        done={getStepNumber(props.currentStep) > getStepNumber(props.step)}
        current={current}
        subtitle={
          currentProcessStepData && (
            <Flex>
              <Regular color='HestiaGrey' fontSize='16px'>
                <Tooltip>
                  <Flex gap='8px' alignItems='center'>
                    <BiCalendar />
                    <Text as='span'>
                      {current
                        ? `Commencé ${dayjs(currentProcessStepData.startedAt)
                            .locale('fr')
                            .fromNow()}`
                        : `Terminé le ${dayjs(currentProcessStepData.finishedAt).format(
                            'DD/MM/YYYY (HH:MM)',
                          )}`}
                    </Text>
                  </Flex>
                </Tooltip>
              </Regular>
            </Flex>
          )
        }
        rightPart={
          <Flex h='100%' alignItems='center' gap='16px'>
            {getTeamBadge(assetProcessSteps[props.step].team)}
            <Button
              size='sm'
              color={current ? 'white' : 'HestiaGrey'}
              // bg={current ? 'HestiaPintGreen' : 'initial'}
              colorScheme={current ? 'green' : 'gray'}
              onClick={onOpen}
            >
              Voir plus
            </Button>
          </Flex>
        }
      />
    </>
  )
}

export default AssetProcessStepTodo
