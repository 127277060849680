import { authenticatedPutQuery } from '../../../utility/queries/authenticatedPutQuery'
import { AssetAdStatus } from '../../../interfaces/prisma'

export interface UpdateAssetAdInputs {
  hestiaStatus?: AssetAdStatus
  userStatus?: AssetAdStatus
  placeId?: string
  optionYears?: number
  hestiaPrice?: number
  acquisitionPrice?: number
  depositPercent?: number
  cashbackOnRent?: number
  rentPrice?: number
  id: string
  sendOfferEmail?: boolean
  rejectionReason?: string
  rejectionMessage?: string
  notionUrl?: string
}

export const updateAssetAd = async (body: UpdateAssetAdInputs) => {
  return await authenticatedPutQuery(`/admin/assetAds/${body.id}`, {
    ...body,
    id: undefined,
  }).then((data) => data.data)
}
