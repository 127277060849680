export enum DateRangeEnum {
  ASAP = 'ASAP',
  '3_MONTHS' = '3_MONTHS',
  '6_MONTHS' = '6_MONTHS',
  '9_MONTHS' = '9_MONTHS',
}

export enum CoBuyersEnum {
  'ONE' = 1,
  'TWO' = 2,
}

export enum EmploymentSectorEnum {
  'PRIVATE' = 'PRIVATE',
  'PUBLIC' = 'PUBLIC',
  'INDEPENDENT' = 'INDEPENDENT',
  'OTHER' = 'OTHER',
}

export enum PublicStatusEnum {
  'TITULAIRE' = 'TITULAIRE',
  'STAGIAIRE' = 'STAGIAIRE',
  'CONTRACTUEL' = 'CONTRACTUEL',
}

export enum ContractTypesEnum {
  'CDD' = 'CDD',
  'CDI' = 'CDI',
}

export enum FolderStatusEnum {
  IN_PROGRESS = 'IN_PROGRESS',
  SUBMITTED = 'SUBMITTED',
  ACCEPTED = 'ACCEPTED',
}

export enum AccountStateEnum {
  'NEED_CALL' = 'NEED_CALL',
  'NEED_SYNC_ACCOUNTS' = 'NEED_SYNC_ACCOUNTS',
  'NEED_FIND_ASSET' = 'NEED_FIND_ASSET',
  'RENTING' = 'RENTING',
  'CHURNED' = 'CHURNED',
}

export enum TinkMarketEnum {
  'FR' = 'FR',
  'NL' = 'NL',
  'EE' = 'EE',
  'CH' = 'CH',
  'PL' = 'PL',
  'LT' = 'LT',
  'NO' = 'NO',
  'AT' = 'AT',
  'FI' = 'FI',
  'ES' = 'ES',
  'DE' = 'DE',
  'IT' = 'IT',
  'PT' = 'PT',
  'SE' = 'SE',
  'GB' = 'GB',
}

export const tinkMarkets: Record<TinkMarketEnum, { label: string }> = {
  [TinkMarketEnum.FR]: {
    label: 'France 🇫🇷',
  },
  [TinkMarketEnum.NL]: {
    label: 'Pays-Bas 🇳🇱',
  },
  [TinkMarketEnum.EE]: {
    label: 'Estonie 🇪🇪',
  },
  [TinkMarketEnum.CH]: {
    label: 'Suisse 🇨🇭',
  },
  [TinkMarketEnum.PL]: {
    label: 'Pologne 🇵🇱',
  },
  [TinkMarketEnum.LT]: {
    label: 'Lituanie 🇱🇹',
  },
  [TinkMarketEnum.NO]: {
    label: 'Norvège 🇳🇴',
  },
  [TinkMarketEnum.AT]: {
    label: 'Autriche 🇦🇹',
  },
  [TinkMarketEnum.FI]: {
    label: 'Finlande 🇫🇮',
  },
  [TinkMarketEnum.ES]: {
    label: 'Espagne 🇪🇸',
  },
  [TinkMarketEnum.DE]: {
    label: 'Allemagne 🇩🇪',
  },
  [TinkMarketEnum.IT]: {
    label: 'Italie 🇮🇹',
  },
  [TinkMarketEnum.PT]: {
    label: 'Portugal 🇵🇹',
  },
  [TinkMarketEnum.SE]: {
    label: 'Suède 🇸🇪',
  },
  [TinkMarketEnum.GB]: {
    label: 'Royaume-Uni 🇬🇧',
  },
}
