import { Flex, CircularProgress, CircularProgressLabel, Text, Accordion } from '@chakra-ui/react'
import React, { useState } from 'react'
import { BiLinkAlt, BiUser, BiMessageDetail, BiCoinStack } from 'react-icons/bi'
import { useNavigate, useParams } from 'react-router-dom'
import Header from '../../components/Typo/Header'
import LargeBold from '../../components/Typo/LargeBold'
import Regular from '../../components/Typo/Regular'
import { getAssetAdById } from '../queries/getAssetAdById'
import { useQuery } from 'react-query'
import { useAuth } from '../../../auth/AuthProvider'
import StepItem from '../Steps/StepItem'
import StepTodo from '../Steps/StepTodo'
import {
  AssetProcessCategoryEnum,
  assetProcessCategories,
  AssetProcessStepEnum,
  assetProcessSteps,
  getStepNumber,
} from '../../../shared/backShared/assetProcessSteps'
import AssetProcessStepTodo from './AssetProcessStepTodo'

type Props = {}

const AssetAdProcessList = (props: Props) => {
  const { id } = useParams()
  const assetAd = useQuery(['assetAd', { id }], () => getAssetAdById(id!))
  const authContext = useAuth()
  const navigate = useNavigate()
  const currentStep = assetAd.data?.processStep || null

  if (!currentStep) {
    return <></>
  }

  const getDefaultIndex = () => {
    {
      if (assetAd.data?.isLost) {
        return []
      }
      if (assetProcessCategories[AssetProcessCategoryEnum.PRE_OFFER].steps.includes(currentStep)) {
        return [0]
      } else if (
        assetProcessCategories[AssetProcessCategoryEnum.PURCHASE_OFFER].steps.includes(currentStep)
      ) {
        return [1]
      } else if (
        assetProcessCategories[AssetProcessCategoryEnum.PRE_PROMISE].steps.includes(currentStep)
      ) {
        return [2]
      } else if (
        assetProcessCategories[AssetProcessCategoryEnum.PROMISE].steps.includes(currentStep)
      ) {
        return [3]
      } else {
        return []
      }
    }
  }

  return (
    <Flex direction='column' w='100%'>
      <Accordion w='100%' defaultIndex={getDefaultIndex()} allowMultiple allowToggle>
        <Flex direction='column' gap='24px'>
          <StepItem
            currentStep={getStepNumber(currentStep)}
            current={assetProcessCategories[AssetProcessCategoryEnum.PRE_OFFER].steps.includes(
              currentStep,
            )}
            totalSteps={6}
            text={assetProcessCategories[AssetProcessCategoryEnum.PRE_OFFER].label}
          >
            <AssetProcessStepTodo currentStep={currentStep} step={AssetProcessStepEnum.ANALYSIS} />
            <AssetProcessStepTodo
              currentStep={currentStep}
              step={AssetProcessStepEnum.SEND_USER_OFFER}
            />
            <AssetProcessStepTodo
              currentStep={currentStep}
              step={AssetProcessStepEnum.USER_ANSWERED_OFFER}
            />
            <AssetProcessStepTodo
              currentStep={currentStep}
              step={AssetProcessStepEnum.FOLDER_SENT}
            />
            <AssetProcessStepTodo
              currentStep={currentStep}
              step={AssetProcessStepEnum.FOLDER_ACCEPTED}
            />
            <AssetProcessStepTodo
              currentStep={currentStep}
              step={AssetProcessStepEnum.NEGOTIATION}
            />
          </StepItem>
          <StepItem
            current={assetProcessCategories[AssetProcessCategoryEnum.PURCHASE_OFFER].steps.includes(
              currentStep,
            )}
            currentStep={getStepNumber(currentStep) - 6}
            totalSteps={5}
            text={assetProcessCategories[AssetProcessCategoryEnum.PURCHASE_OFFER].label}
          >
            <AssetProcessStepTodo
              currentStep={currentStep}
              step={AssetProcessStepEnum.SEND_PURCHASE_OFFER}
            />
            <AssetProcessStepTodo
              currentStep={currentStep}
              step={AssetProcessStepEnum.SIGNED_PURCHASE_OFFER}
            />
            <AssetProcessStepTodo
              currentStep={currentStep}
              step={AssetProcessStepEnum.SEND_USER_CONTRACT}
            />
            <AssetProcessStepTodo
              currentStep={currentStep}
              step={AssetProcessStepEnum.SIGNED_USER_CONTRACT}
            />
            <AssetProcessStepTodo
              currentStep={currentStep}
              step={AssetProcessStepEnum.DOWN_PAYMENT}
            />
          </StepItem>
          <StepItem
            current={assetProcessCategories[AssetProcessCategoryEnum.PRE_PROMISE].steps.includes(
              currentStep,
            )}
            currentStep={getStepNumber(currentStep) - 6 - 5}
            totalSteps={7}
            text={assetProcessCategories[AssetProcessCategoryEnum.PRE_PROMISE].label}
          >
            <AssetProcessStepTodo
              currentStep={currentStep}
              step={AssetProcessStepEnum.VISIT_BOOKING}
            />
            <AssetProcessStepTodo
              currentStep={currentStep}
              step={AssetProcessStepEnum.VISIT_SUMMARY_RECEIVED}
            />
            <AssetProcessStepTodo
              currentStep={currentStep}
              step={AssetProcessStepEnum.VISIT_SUMMARY_REVIEWED}
            />
            <AssetProcessStepTodo
              currentStep={currentStep}
              step={AssetProcessStepEnum.NOTARY_CONNECTION}
            />
            <AssetProcessStepTodo
              currentStep={currentStep}
              step={AssetProcessStepEnum.NOTARY_AUDIT_REQUEST}
            />
            <AssetProcessStepTodo
              currentStep={currentStep}
              step={AssetProcessStepEnum.NOTARY_AUDIT_RECEIVED}
            />
            <AssetProcessStepTodo
              currentStep={currentStep}
              step={AssetProcessStepEnum.NOTARY_AUDIT_REVIEWED}
            />
          </StepItem>
          <StepItem
            current={assetProcessCategories[AssetProcessCategoryEnum.PROMISE].steps.includes(
              currentStep,
            )}
            currentStep={getStepNumber(currentStep) - 6 - 5 - 7}
            totalSteps={3}
            text={assetProcessCategories[AssetProcessCategoryEnum.PROMISE].label}
          >
            <AssetProcessStepTodo
              currentStep={currentStep}
              step={AssetProcessStepEnum.PROMISE_WRITING}
            />
            <AssetProcessStepTodo
              currentStep={currentStep}
              step={AssetProcessStepEnum.PROMISE_RECEIVED}
            />
            <AssetProcessStepTodo
              currentStep={currentStep}
              step={AssetProcessStepEnum.PROMISE_DATE_FIXING}
            />
            <AssetProcessStepTodo
              currentStep={currentStep}
              step={AssetProcessStepEnum.PROMISE_SIGNING}
            />
          </StepItem>
        </Flex>
      </Accordion>
    </Flex>
  )
}

export default AssetAdProcessList
