import { FormikValues, useFormikContext } from 'formik'
import React, { useEffect } from 'react'
import _ from 'lodash'
import { useDebouncedCallback } from 'use-debounce'
import { usePreviousValue } from '../../../utility/hooks/usePreviousValue'

type Props = {
  onChange: (values: FormikValues) => void
}

const FormikOnChange = (props: Props) => {
  const formik = useFormikContext<FormikValues>()
  const prevValues: FormikValues | undefined = usePreviousValue(formik.values)

  useEffect(() => {
    if (_.isEqual(formik.values, prevValues)) return

    props.onChange(formik.values)
  }, [formik.values])

  return <></>
}

export default FormikOnChange
