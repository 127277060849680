import { AssetProcessStepEnum } from '../shared/backShared/assetProcessSteps'
import { RejectionReasonEnum } from '../shared/backShared/assetRejectionTypes'

export enum EmploymentSectorEnum {
  PRIVATE = 'PRIVATE',
  PUBLIC = 'PUBLIC',
  OTHER = 'OTHER',
}

export const employmentSectors: Record<EmploymentSectorEnum, { label: string }> = {
  [EmploymentSectorEnum.PRIVATE]: { label: 'Privé' },
  [EmploymentSectorEnum.PUBLIC]: { label: 'Public' },
  [EmploymentSectorEnum.OTHER]: { label: 'Autre' },
}

// Enum and mapping for Public Statuses
export enum PublicStatusEnum {
  TITULAIRE = 'TITULAIRE',
  STAGIAIRE = 'STAGIAIRE',
  CONTRACTUEL = 'CONTRACTUEL',
}

export const publicStatuses: Record<PublicStatusEnum, { label: string }> = {
  [PublicStatusEnum.TITULAIRE]: { label: 'Titulaire' },
  [PublicStatusEnum.STAGIAIRE]: { label: 'Stagiaire' },
  [PublicStatusEnum.CONTRACTUEL]: { label: 'Contractuel' },
}

// Enum and mapping for Contract Types
export enum ContractTypesEnum {
  CDD = 'CDD',
  CDI = 'CDI',
}

export const contractTypes: Record<ContractTypesEnum, { label: string }> = {
  [ContractTypesEnum.CDD]: { label: 'CDD' },
  [ContractTypesEnum.CDI]: { label: 'CDI' },
}

// Enum and mapping for Different Professional Situations
export enum ProfessionalSituationEnum {
  SELF_EMPLOYED = 'self_employed',
  RETIRED = 'retired',
  BUSINESS_OWNER = 'business_owner',
  LAWYER = 'lawyer',
  DOCTOR = 'doctor',
  LIBERAL = 'liberal',
  JOBLESS = 'jobless',
  TRADER = 'trader',
  FARMER = 'farmer',
  PARENTAL_LEAVE = 'parental_leave',
  CRAFTSMAN = 'craftsman',
  STUDENT = 'student',
  SALARIED_DIRECTOR = 'salaried_director',
  INTERIM = 'interim',
  CASUAL_SHOW_BUSINESS_WORKER = 'casual_show_business_worker',
  PORTAGE_SALARIAL = 'portage_salarial',
  JOBSEEKER = 'jobseeker',
  CHILDMINDER = 'childminder',
}

export const professionalSituations: Record<ProfessionalSituationEnum, { label: string }> = {
  [ProfessionalSituationEnum.SELF_EMPLOYED]: {
    label: 'Travailleur indépendant ou autoentrepreneur',
  },
  [ProfessionalSituationEnum.RETIRED]: { label: 'Retraité' },
  [ProfessionalSituationEnum.BUSINESS_OWNER]: { label: "Chef d'entreprise" },
  [ProfessionalSituationEnum.LAWYER]: { label: 'Professions libérales juridiques' },
  [ProfessionalSituationEnum.DOCTOR]: { label: 'Professions libérales médicales et paramédicales' },
  [ProfessionalSituationEnum.LIBERAL]: {
    label: 'Professions libérales hors médical, paramédical et juridique',
  },
  [ProfessionalSituationEnum.JOBLESS]: { label: 'Sans profession' },
  [ProfessionalSituationEnum.TRADER]: { label: 'Commercant' },
  [ProfessionalSituationEnum.FARMER]: { label: 'Agriculteur' },
  [ProfessionalSituationEnum.PARENTAL_LEAVE]: { label: 'Congé parental' },
  [ProfessionalSituationEnum.CRAFTSMAN]: { label: 'Artisan' },
  [ProfessionalSituationEnum.STUDENT]: { label: 'Étudiant' },
  [ProfessionalSituationEnum.SALARIED_DIRECTOR]: { label: 'Gérant salarié' },
  [ProfessionalSituationEnum.INTERIM]: { label: 'Intérimaire' },
  [ProfessionalSituationEnum.CASUAL_SHOW_BUSINESS_WORKER]: { label: 'Intermittent du spectacle' },
  [ProfessionalSituationEnum.PORTAGE_SALARIAL]: { label: 'Portage salarial' },
  [ProfessionalSituationEnum.JOBSEEKER]: { label: "Recherche d'emploi" },
  [ProfessionalSituationEnum.CHILDMINDER]: { label: 'Assistant maternel' },
}

// Enum and mapping for Contract Types with specifics
export enum DetailedContractTypesEnum {
  PERMANENT_EXECUTIVE = 'permanent_executive',
  PERMANENT_EXECUTIVE_TRIAL = 'permanent_executive_trial',
  PERMANENT_NON_EXECUTIVE = 'permanent_non_executive',
  PERMANENT_NON_EXECUTIVE_TRIAL = 'permanent_non_executive_trial',
}

export const detailedContractTypes: Record<DetailedContractTypesEnum, { label: string }> = {
  [DetailedContractTypesEnum.PERMANENT_EXECUTIVE]: { label: "CDI cadre, hors période d'essai" },
  [DetailedContractTypesEnum.PERMANENT_EXECUTIVE_TRIAL]: { label: "CDI cadre, en période d'essai" },
  [DetailedContractTypesEnum.PERMANENT_NON_EXECUTIVE]: {
    label: "CDI non-cadre, hors période d'essai",
  },
  [DetailedContractTypesEnum.PERMANENT_NON_EXECUTIVE_TRIAL]: {
    label: "CDI non-cadre, en période d'essai",
  },
}

// Enum and mapping for Public Contract Options
export enum PublicContractEnum {
  TENURE = 'tenure',
  CONTRACTUAL_CDI = 'contractuel_cdi',
  CONTRACTUAL_CDD = 'contractuel_cdd',
  INTERNSHIP = 'internship',
  MILITARY = 'military',
}

export const publicContracts: Record<PublicContractEnum, { label: string }> = {
  [PublicContractEnum.TENURE]: { label: 'Titulaire' },
  [PublicContractEnum.CONTRACTUAL_CDI]: { label: 'Contractuel en CDI' },
  [PublicContractEnum.CONTRACTUAL_CDD]: { label: 'Contractuel en CDD' },
  [PublicContractEnum.INTERNSHIP]: { label: 'Stagiaire' },
  [PublicContractEnum.MILITARY]: { label: 'Militaire' },
}

export const UserLostReasonEnum = {
  BUDGET_TOO_LOW: 'BUDGET_TOO_LOW',
  LOAN_INELIGIBILITY_POST_HESITA_PERIOD: 'LOAN_INELIGIBILITY_POST_HESITA_PERIOD',
  NO_LONGER_INTERESTED: 'NO_LONGER_INTERESTED',
  CHANGE_OF_PROJECT: 'CHANGE_OF_PROJECT',
  NO_ELIGIBLE_PROPERTY: 'NO_ELIGIBLE_PROPERTY',
  NO_RESPONSE: 'NO_RESPONSE',
  APPLICATION_NOT_RECEIVED: 'APPLICATION_NOT_RECEIVED',
  INCORRECT_CONTACT_DETAILS: 'INCORRECT_CONTACT_DETAILS',
  TOO_EXPENSIVE: 'TOO_EXPENSIVE',
  LOCALISATION_INELIGIBLE: 'LOCALISATION_INELIGIBLE',
  RENTAL_INVESTMENT_PROJECT: 'RENTAL_INVESTMENT_PROJECT',
  BANK_ELIGIBLE: 'BANK_ELIGIBLE',
  CURIOUS: 'CURIOUS',
  WANTS_NEW_CONSTRUCTION: 'WANTS_NEW_CONSTRUCTION',
}

export const userLostReasons = [
  {
    value: UserLostReasonEnum.BUDGET_TOO_LOW,
    label: 'Budget insuffisant',
  },
  {
    value: UserLostReasonEnum.LOAN_INELIGIBILITY_POST_HESITA_PERIOD,
    label: 'Profil inéligible',
  },
  {
    value: UserLostReasonEnum.NO_LONGER_INTERESTED,
    label: 'Plus intéréssé',
  },
  {
    value: UserLostReasonEnum.NO_ELIGIBLE_PROPERTY,
    label: 'Pas de bien éligible',
  },
  {
    value: UserLostReasonEnum.NO_RESPONSE,
    label: 'Pas de réponse',
  },
  {
    value: UserLostReasonEnum.APPLICATION_NOT_RECEIVED,
    label: 'Dossier non reçu',
  },
  {
    value: UserLostReasonEnum.INCORRECT_CONTACT_DETAILS,
    label: 'Coordonnées erronées',
  },
  {
    value: UserLostReasonEnum.TOO_EXPENSIVE,
    label: 'Trop cher',
  },
  {
    value: UserLostReasonEnum.LOCALISATION_INELIGIBLE,
    label: 'Localisation inéligible',
  },
  {
    value: UserLostReasonEnum.RENTAL_INVESTMENT_PROJECT,
    label: 'Investissement locatif',
  },
  {
    value: UserLostReasonEnum.BANK_ELIGIBLE,
    label: 'Eligible à la banque',
  },
  {
    value: UserLostReasonEnum.CURIOUS,
    label: 'Curieux',
  },
  {
    value: UserLostReasonEnum.WANTS_NEW_CONSTRUCTION,
    label: 'VEFA uniquement',
  },
]

export type UserLostReasonEnum = typeof UserLostReasonEnum[keyof typeof UserLostReasonEnum]

export const LostReasonEnum = {
  FAILED_NEGOTIATION: 'FAILED_NEGOTIATION',
  SOLD: 'SOLD',
  NO_ANSWER: 'NO_ANSWER',
  HESTIA_PROJECT_CHANGE: 'HESTIA_PROJECT_CHANGE',
  ANALYSIS: 'ANALYSIS',
  ASSET_ABANDONED: 'ASSET_ABANDONED',
  BUYER_INELIGIBLE: 'BUYER_INELIGIBLE',
  USER_DELETED: 'USER_DELETED',
}

export const lostReasons = [
  {
    value: LostReasonEnum.ANALYSIS,
    label: "NO-GO de l'analyse",
  },
  {
    value: LostReasonEnum.FAILED_NEGOTIATION,
    label: 'Négociation echouée',
  },
  {
    value: LostReasonEnum.SOLD,
    label: 'Bien vendu',
  },
  {
    value: LostReasonEnum.NO_ANSWER,
    label: 'Pas de réponse du client',
  },
  {
    value: LostReasonEnum.HESTIA_PROJECT_CHANGE,
    label: 'Ne souhaite plus passer par Hestia',
  },
  {
    value: LostReasonEnum.ASSET_ABANDONED,
    label: 'Ne souhaite plus ce bien',
  },
  {
    value: LostReasonEnum.BUYER_INELIGIBLE,
    label: 'Budget non cohérent',
  },
  {
    value: LostReasonEnum.USER_DELETED,
    label: 'Supprimé par le client',
  },
]

export type LostReasonEnum = typeof LostReasonEnum[keyof typeof LostReasonEnum]

export type VisitInformations = {
  id: string
  createdAt: Date
  updatedAt: Date
  propertyRating?: number
  favoritePartOfVisit?: string
  plannedRenovations?: string
  environmentDetails?: string
  assetAdId: string
  visitDate?: Date
}

export type AssetAd = {
  id: string
  createdAt: Date
  updatedAt: Date
  userId: string
  name: string
  url: string | null
  postalCode: string | null
  marketValue: number | null
  agencyFees: number | null
  notaryFees: number | null
  propretyTax: number | null
  condominiumCharges: number | null
  hestiaStatus: AssetAdStatus
  userStatus: AssetAdStatus
  hestiaPrice: number | null
  rentPrice: number | null
  cashbackOnRent: number | null
  depositPercent: number | null
  acquisitionPrice: number | null
  rejectionMessage: string | null
  optionYears: number | null
  nbOffersSent: number
  isLost: boolean
  notes: string | null
  hubspotDealId: string | null
  hubspotCompanyId: string | null
  notionUrl: string | null
  processStep: AssetProcessStepEnum
  assetProcessSteps: AssetProcessStep[]
  rejectionReason?: RejectionReasonEnum
  lostReason?: LostReasonEnum
  sellerPhone?: string
}

export type AssetProcessStep = {
  id: string
  createdAt: Date
  updatedAt: Date
  assetId: string
  step: AssetProcessStepEnum
  startedAt: Date | null
  finishedAt: Date | null
}

export enum AssetAdStatus {
  PENDING = 'PENDING',
  REJECTED = 'REJECTED',
  ACCEPTED = 'ACCEPTED',
}

export type Address = {
  id: string
  createdAt: Date
  updatedAt: Date
  placeId: string
  country: string
  city: string
  postalCode: string
  street: string
  streetNumber: string
  administrativeAreaLevel1: string
  administrativeAreaLevel2: string
  lat: number
  lng: number
  formattedAddress: string | null
}

export type TinkAccountModel = {
  id: string
  createdAt: Date
  updatedAt: Date
  userDeleted: boolean
  userId: string
  tinkId: string
  name: string
  type: string
  balanceBookedScale: number | null
  balanceBookedUnscaledValue: number | null
  balanceAvailableScale: number | null
  balanceAvailableUnscaledValue: number | null
  currency: string | null
  customerSegment: TinkCustomerSegmentEnum
  financialInstitutionId: string | null
  iban: string | null
  bban: string | null
  bic: string | null
  lastRefreshed: Date
}

export type TinkTransactionModel = {
  id: string
  createdAt: Date
  updatedAt: Date
  accountId: string
  tinkId: string
  bookedDateTime: Date | null
  bookedDate: Date | null
  amountCurrencyCode: string
  amountScaleValue: number
  amountUnscaledValue: number
  descriptionDisplay: string | null
  descriptionOriginal: string | null
  merchantCategoryCode: string | null
  merchantName: string | null
  providerMutability: string | null
  status: TinkTransactionStatus
  type: TinkTransactionType
}

export enum TinkCustomerSegmentEnum {
  UNDEFINED_CUSTOMER_SEGMENT = 'UNDEFINED_CUSTOMER_SEGMENT',
  PERSONAL = 'PERSONAL',
  BUSINESS = 'BUSINESS',
}

export enum TinkTransactionStatus {
  UNDEFINED = 'UNDEFINED',
  BOOKED = 'BOOKED',
  PENDING = 'PENDING',
}

export enum TinkTransactionType {
  UNDEFINED = 'UNDEFINED',
  CREDIT_CARD = 'CREDIT_CARD',
  PAYMENT = 'PAYMENT',
  WITHDRAWAL = 'WITHDRAWAL',
  DEFAULT = 'DEFAULT',
  TRANSFER = 'TRANSFER',
}
